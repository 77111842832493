import React, { useEffect, useState } from "react";
import ApiService from "../../Service/ApiService";
import ModalCom from "../Helpers/ModalCom";
import { useDispatch } from "react-redux";
import { setIsGeoSupported } from "../../store/site";

export const GeoFence = () => {
  const [hideModal, setHideModal] = useState(false);
  const [isSupported, setIsSupported] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    ApiService.get("/geo/isSupportedGeo")
      .then(
        (data) => {
          if (data.status === 200) {
            setIsSupported(data.data.isSupported);
            dispatch(setIsGeoSupported(data.data.isSupported));
            return;
          }

          throw new Error("Error when fetching for geo information");
        },
        (err) => {
          throw err;
        }
      )
      .catch((e) => {
        console.error(e);
      });
  }, []);

  // null for before fetched
  if (isSupported === null || isSupported || hideModal) {
    return null;
  }

  return (
    <ModalCom>
      <div className="lg:w-[600px] w-full bg-white lg:rounded-xl p-8">
        <div className="mb-2">
        FracX is unavailable in your country or region.
        </div>
        <div className="mb-4">
          Please stay tuned for when FracX will be available in your region.
        </div>
        <div className="flex justify-center">
          <button
            type="button"
            className="text-white primary-gradient text-lg tracking-wide mx-12 rounded-full px-8 py-2"
            onClick={() => {
              setHideModal(true);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </ModalCom>
  );
};
