import React, { Suspense } from "react";
import loginThumb from "../../assets/images/auth-thumb.svg";
// import logo from "../../assets/images/logo-segmint-login-1.png";
// import logo from "../../assets/images/SEGMINT-Logo_Blue.png";

export default function LoginLayout({ slogan, children }) {
  const checkScreenHeight = window.screen.height;
  let screen = "";
  if (checkScreenHeight <= 950) {
    screen = "h-screen";
    // screen = "h-[950px]";
  } else {
    screen = "h-screen";
  }
  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center w-full h-[70vh] font-serif text-2xl">
          Loading...
        </div>
      }
    >
      <div className="layout-wrapper">
        <div className={`main-wrapper w-full ${screen}`}>
          <div className="flex w-full">
            <div
              className="xl:flex hidden p-[70px] flex-col justify-between primary-gradient"
              style={{ flex: "50%", minHeight: "100vh", maxHeight: "100%" }}
            >
              {/* <div className="logo">
                <img
                  src={logo}
                  alt="logo"
                  width="34%"
                  // style={{ marginLeft: "-5%", marginTop: "-10%" }}
                />
              </div> */}
              <div className="thumbnail flex justify-center">
                <img
                  src={loginThumb}
                  alt="login-thumb"
                  style={{
                    objectFit: "contain",
                    width: "75%",
                    height: "100%",
                  }}
                />
              </div>
              <div className="article">
                <p
                  className="text-[50px] text-center font-bold leading-[72px] text-white"
                  style={{ wordWrap: "break-word" }}
                >
                  {slogan}
                </p>
              </div>
            </div>
            <div
              className=" flex justify-center items-center"
              style={{ flex: "50%", minHeight: "100vh", maxHeight: "100%" }}
            >
              {children && children}
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
}
