import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import drawerReducer from "./drawer";
import walletReducer from "./wallet";
import userReducer from "./userProfile";
import ipfsGatewayReducer from "./gateway";
import siteReducer from "./site";

const combinedReducer = combineReducers({
  drawer: drawerReducer,
  wallet: walletReducer,
  user: userReducer,
  ipfsGateway: ipfsGatewayReducer,
  site: siteReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "counter/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
});
