import { getProfile } from "../apis/apis";
import ApiService from "./ApiService";

export const checkVerified = async () => {
  const user = localStorage.getItem("user");
  if (user) {
    console.log("Calling api ");
    return await ApiService.get(getProfile)
      .then((user) => {
        console.log(user.data);
        const verified = user.data.verified;
        return verified;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }
  return false;
};
