import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import SearchCom from "../Helpers/SearchCom";
// import CustomCheckbox from "../Admin/CheckBox";
import NoData from "../NoData/NoData";
import ApiService from "../../Service/ApiService";
import Web3 from "web3";
import { useAccount, useSwitchNetwork } from "wagmi";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import ModalCom from "../Helpers/ModalCom";
import { useLocation, useNavigate } from "react-router-dom";

var timer;
export default function PendingProposalTable({
  contractAddress,
  pendingProposalData,
  setReloadAPI,
  chainId,
}) {
  const navigate = useNavigate();
  const { switchNetwork } = useSwitchNetwork();
  const location = useLocation();
  //   const walletInfoFromRedux = useSelector((state) => state.wallet.walletInfo);
  const account = useAccount();
  const [transactionHash, setTransactionHash] = useState(null);
  const [transactionLoader, setTransactionLoader] = useState(false);
  const [buying, setBuying] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(0);
  const [deployTransactionCheck, setDeployTransactionCheck] = useState(false);
  const isGuestRoute = location.pathname.includes("/guest");

  const COLUMNS = [
    {
      Header: "",
      accessor: "checkbox",
    },
    {
      Header: "List",
      accessor: "list",
      Cell: ({ row }) => {
        return row.index + 1 || <span>NA</span>;
      },
    },
    {
      Header: "Proposal Type",
      accessor: "proposalType",
      Cell: ({ row }) => {
        return row.original.proposalType || <span>NA</span>;
      },
    },
    {
      Header: "Approved Votes",
      accessor: "approvedVotesBySigner",
      Cell: ({ row }) => {
        return row.original.approvedVotesBySigner || <span>NA</span>;
      },
    },
    {
      Header: "Revoked Votes",
      accessor: "revokedVotesBySigner",
      Cell: ({ row }) => {
        return row.original.revokedVotesBySigner || <span>NA</span>;
      },
    },
    {
      Header: "Expiration",
      accessor: "expiration",
      Cell: ({ row }) => {
        let date = "";
        if (row.original.expiration) {
          const conversion = new Date(row.original.expiration * 1000); // Convert timestamp to milliseconds
          date = conversion.toLocaleDateString(); // Convert date to string format
        }
        return row.original.expiration ? date : <span>NA</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        return row.original.status || <span>NA</span>;
      },
    },
    {
      Header: "Action",
      accessor: "",
      Cell: ({ row }) => {
        let approved = false;
        let lockProposal = false;
        const walletAddress = account.address;

        let isProposer =
          account?.address?.toLowerCase() ==
          row?.original?.proposer?.toLowerCase();
        approved = row?.original?.signers?.find(
          (i) => i?.wallet?.toLowerCase() == walletAddress?.toLowerCase()
        )?.approved;
        lockProposal =
          row?.original?.proposalType == "LOCK" ||
          row?.original?.proposalType == "UNLOCK";

        return (
          <>
            <div className="flex flex-row gap-2">
              {lockProposal && (
                <button
                  type="button"
                  onClick={() =>
                    navigate(
                      isGuestRoute
                        ? `/guest/lock-proposal/${contractAddress}/${row?.original?.proposalId}/${row?.original?.expiration}/${chainId}`
                        : `/lock-proposal/${contractAddress}/${row?.original?.proposalId}/${row?.original?.expiration}/${chainId}`
                    )
                  }
                  className="w-[100px] flex h-12 justify-center items-center btn-gradient text-center text-base rounded-full text-white"
                >
                  View
                </button>
              )}
            </div>
            <div className="flex flex-row gap-2">
              {!lockProposal && isProposer && (
                <button
                  type="button"
                  onClick={() => proposalVotesHandler("revoke", row.original)}
                  className="w-[100px] flex h-12 justify-center items-center btn-gradient text-center text-base rounded-full text-white"
                >
                  Revoke
                </button>
              )}
            </div>
            <div className="flex flex-row gap-2">
              <>
                {!lockProposal && !approved && (
                  <button
                    type="button"
                    onClick={() => proposalVotesHandler("accept", row.original)}
                    className="w-[100px] flex h-12 justify-center items-center btn-gradient text-center text-base rounded-full text-white"
                  >
                    Accept
                  </button>
                )}
              </>
            </div>
          </>
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => pendingProposalData, [pendingProposalData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state, // Add the state object for globalFilter
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );
  const { globalFilter } = state;

  const proposalVotesHandler = async (type1, data) => {
    const walletAddress = await account.address;
    const walletChainId = await account?.connector?.getChainId();
    if (chainId === walletChainId) {
      await switchNetwork?.(chainId);
    } else {
      setBuying(true);
      setTransactionLoader(true);
      setTransactionStatus(0);
      const provider = await account.connector.getProvider();
      const x = new Web3(provider.provider);
      let path;
      let body;
      if (type1 === "accept") {
        if (data["proposalType"] === "Update Min Signature") {
          path = `SegMint_MultiSig/${walletChainId}/v1/approveMinSignatureProposal`;
          body = {
            minSignatureProposalID_: data.proposalId,
            SegMintMultiSigAddress: contractAddress,
            walletAddress: walletAddress,
            blockchain_env: "Testnet",
          };
        } else {
          path = `SegMint_MultiSig/${walletChainId}/v1/approveSignerProposal`;
          body = {
            signerProposalID_: data.proposalId,
            SegMintMultiSigAddress: contractAddress,
            walletAddress: walletAddress,
            blockchain_env: "Testnet",
          };
        }
      }
      if (type1 === "revoke") {
        if (data["proposalType"] === "Update Min Signature") {
          path = `SegMint_MultiSig/${walletChainId}/v1/revokeMinSignatureProposal`;
          body = {
            minSignatureProposalID_: data.proposalId,
            SegMintMultiSigAddress: contractAddress,
            walletAddress: walletAddress,
            blockchain_env: "Testnet",
          };
        } else {
          path = `SegMint_MultiSig/${walletChainId}/v1/revokeSignerProposal`;
          body = {
            signerProposalID_: data.proposalId,
            SegMintMultiSigAddress: contractAddress,
            walletAddress: walletAddress,
            blockchain_env: "Testnet",
          };
        }
      }
      ApiService.ledgerPost(path, body)
        .then((res) => {
          x.eth.sendTransaction(
            res.data.raw_data,
            async function (err, result) {
              if (err) {
                console.log(err);
                toast.error("Internal server error. Please try later!");
                setTransactionLoader(false);
                setBuying(false);
              }
              if (result) {
                setTransactionLoader(false);
                setTransactionHash(result);
                setDeployTransactionCheck(false);

                toast.success("Transaction is Successful");
              }
            }
          );
        })
        .catch((err) => console.log(err));
    }
  };
  async function isPending() {
    const provider = await account.connector.getProvider();
    const x = new Web3(provider.provider);
    return (await x.eth.getTransactionReceipt(transactionHash)) == null;
  }

  const checkTransactionHashDeploy = async () => {
    const pending = await isPending();
    const provider = await account.connector.getProvider();
    const x = new Web3(provider.provider);
    if (!pending) {
      setDeployTransactionCheck(true);
      const result = await x.eth.getTransactionReceipt(transactionHash);
      if (result.status) {
        setTransactionStatus(1);
      } else {
        setTransactionStatus(2);
      }
    }
  };
  const initializeTransactionStatus = () => {
    setDeployTransactionCheck(false);
    setBuying(false);
    setTransactionStatus(0);
  };
  useEffect(() => {
    if (transactionHash !== null) {
      timer = setInterval(() => {
        checkTransactionHashDeploy();
      }, 1000);
    }
  }, [transactionHash]);

  useEffect(() => {
    if (transactionHash !== null && deployTransactionCheck) {
      clearInterval(timer);
    }
  }, [deployTransactionCheck]);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={transactionLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container px-4 bg-white mx-auto">
        <div className="grid grid-cols-12 bg-white gap-4">
          <div className="col-span-4">
            <h1 className="text-xl font-bold text-dark-gray tracking-wide">
              Pending Proposals
            </h1>
          </div>
          <div className="col-span-4">
            <SearchCom
              searchPro={globalFilter}
              setSearchPro={setGlobalFilter}
            />
          </div>
          <div className="col-span-4"></div>
        </div>
        <div className="flex mt-2 flex-col">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="w-full overflow-x-auto border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table
                  className="min-w-full divide-y divide-gray-200 dark:divide-gray-700"
                  {...getTableProps()}
                >
                  <thead className="bg-gray-50 dark:bg-gray-800">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className="px-6 py-4"
                        key={Math.random().toString(36).substr(2, 9)}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                            key={Math.random().toString(36).substr(2, 9)}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ""
                                  : ""
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900"
                    {...getTableBodyProps()}
                  >
                    {pendingProposalData.length === 0 ? (
                      <tr>
                        <td colSpan={12}>
                          <NoData info="Ooops, we could not find any user" />
                        </td>
                      </tr>
                    ) : (
                      rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap"
                            key={Math.random().toString(36).substr(2, 9)}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap"
                                  key={Math.random().toString(36).substr(2, 9)}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {buying && (
        <ModalCom>
          <div className="lg:w-[600px] w-full bg-white lg:rounded-2xl">
            <div className="w-full h-[92px] lg:px-10 lg:py-4 px-[30px] py-[23px] border-b border-light-purple flex justify-between items-center">
              {transactionStatus === 1 || transactionStatus === 4 ? (
                <h1 className="text-2xl tracking-wide font-semibold text-dark-gray">
                  Success
                </h1>
              ) : null}
              {transactionStatus === 2 || transactionStatus === 5 ? (
                <h1 className="text-2xl tracking-wide font-semibold text-dark-gray">
                  Failure
                </h1>
              ) : null}
              {transactionStatus === 0 || transactionStatus === 3 ? (
                <h1 className="text-2xl tracking-wide font-semibold text-dark-gray">
                  Please wait
                </h1>
              ) : null}
            </div>
            <div className="">
              {transactionStatus === 1 && (
                <div className="w-full flex flex-col justify-center py-8">
                  <p className="lg:text-md text-md font-md text-center mb-7 flex flex-col justify-center">
                    Success! Your transaction has been processed successfully
                    with{" "}
                    <a
                      className="opensea-contract-link lg:text-md text-md break-words"
                      style={{
                        textDecoration: "none",
                      }}
                      target="_blank"
                      rel="noreferrer"
                      href={
                        "https://testnet.snowtrace.io/tx/" + transactionHash
                      }
                    >
                      {transactionHash}
                    </a>
                  </p>
                  <div className="flex space-x-2.5 justify-center">
                    <button
                      onClick={() => {
                        setBuying(false);
                        setReloadAPI(true);
                      }}
                      type="button"
                      className="text-white primary-gradient text-lg tracking-wide w-[112px] h-[44px] mx-12 rounded-full"
                    >
                      ok
                    </button>
                  </div>
                </div>
              )}
              {transactionStatus === 2 && (
                <div className="w-full flex flex-col justify-center py-10">
                  <p className="lg:text-lg text-md font-md text-center mb-7">
                    Transaction is failed, please retry later.
                  </p>
                  <div className="flex space-x-2.5 justify-center">
                    <button
                      onClick={() => initializeTransactionStatus()}
                      type="button"
                      className="text-white primary-gradient text-lg tracking-wide w-[112px] h-[44px] mx-12 rounded-full"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
              {transactionStatus === 0 && (
                <div className="w-full flex flex-col justify-center py-16">
                  <p className="lg:text-lg text-md font-md text-center mb-7">
                    Please confirm the blockchain transaction and wait for a
                    moment.
                  </p>
                  <div className="flex justify-center items-center">
                    <FadeLoader color="#5356fb" size={300} />
                  </div>
                </div>
              )}
              {transactionStatus === 3 && (
                <div className="w-full flex flex-col justify-center py-16">
                  <p className="lg:text-lg text-md font-md text-center mb-7">
                    Please confirm the API transaction and wait for a moment.
                  </p>
                  <div className="flex justify-center items-center">
                    <FadeLoader color="#5356fb" size={300} />
                  </div>
                </div>
              )}
              {transactionStatus === 4 && (
                <div className="w-full flex flex-col justify-center mb-7 py-10">
                  <p className="lg:text-lg text-md font-md text-center mb-7">
                    Success.
                  </p>
                  <div className="flex space-x-2.5 justify-center">
                    <button
                      onClick={() => initializeTransactionStatus()}
                      type="button"
                      className="text-white primary-gradient text-lg tracking-wide w-[112px] h-[44px] mx-12 rounded-full w-full"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
              {transactionStatus === 5 && (
                <div className="w-full flex flex-col justify-center mb-7 py-10">
                  <p className="lg:text-xl text-md font-md text-center mb-7">
                    Failure.
                  </p>
                  <div className="flex space-x-2.5 justify-center">
                    <button
                      onClick={() => initializeTransactionStatus()}
                      type="button"
                      className="text-white primary-gradient text-lg tracking-wide w-[112px] h-[44px] mx-12 rounded-full w-full"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ModalCom>
      )}
    </>
  );
}
