import { InjectedConnector } from "@web3-react/injected-connector";
// import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
// import { WalletLinkConnector } from "@web3-react/walletlink-connector";

// const walletconnect = new WalletConnectConnector({
//   rpcUrl: `https://goerli.infura.io/v3/${process.env.INFURA_KEY}`,
//   bridge: "https://bridge.walletconnect.org",
//   qrcode: true,
// });

// export const walletlink = new WalletLinkConnector({
//   url: "https://mainnet.infura.io/v3/7d602ed095564f379e242dce15d13fa6",
//   appName: "web3-react-demo",
//   supportedChainIds: [1, 3, 4, 5, 42],
// });

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 43113],
});

// export const connectors = {
//   injected: injected,
//   walletConnect: walletconnect,
//   coinbaseWallet: walletlink,
// };
const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;
const config = {
  development: {
    CHAIN_NAME: "Avalanche FUJI C-Chain",
    CHAIN_ID: 43113,
    CURRENCY: "AVAX",
    EXPLORER: "https://testnet.snowtrace.io/",
    // WEBSOCKET_1: "wss://goerli.infura.io/ws",
    RPC_URL_1: `https://avalanche-fuji.infura.io/v3/${INFURA_KEY}`,
    RPC_URL_2: `https://avalanche-fuji.infura.io/v3/${INFURA_KEY}`,
    RPC_URL_3: `https://avalanche-fuji.infura.io/v3/${INFURA_KEY}`,
  },
  goerli: {
    CHAIN_NAME: "Goerli Testnet",
    CHAIN_ID: 5,
    CURRENCY: "ETH",
    EXPLORER: "https://goerli.etherscan.io/",
    WEBSOCKET_1: "wss://goerli.infura.io/ws",
    RPC_URL_1: `https://goerli.infura.io/v3/${INFURA_KEY}`,
    RPC_URL_2: `https://goerli.infura.io/v3/${INFURA_KEY}`,
    RPC_URL_3: `https://goerli.infura.io/v3/${INFURA_KEY}`,
  },
  production: {
    CHAIN_NAME: "Polygon Mainnet",
    CHAIN_ID: 137,
    CURRENCY: "MATIC",
    EXPLORER: "https://polygonscan.com/",
    WEBSOCKET: "wss://rpc-mainnet.matic.network",
    RPC_URL_1: "https://matic-mainnet.chainstacklabs.com",
    RPC_URL_2: "https://rpc-mainnet.maticvigil.com",
    RPC_URL_3: "https://rpc-mainnet.matic.quiknode.pro",
  },
};

export const networkConfig = config["development"];

export const POLLING_INTERVAL = 12000;
