// DATABASE API
// ------------
// 1. AUTH API
export const signup = "/register";
export const login = "/login";
export const forgetPass = "/forgot";
export const refresh = "/refreshToken";
export const googleSignUp = "/register/google/";
export const googleLogin = "/login/google/";

// 2. PROFILE AND SETTINGS API
export const changePassword = "/profile/changePassword";
export const getFaqs = "/faqs/all";
export const getProfile = "/profile";
export const updateProfile = "/profile";
export const verifyEmail = "/verify";
export const setPassword = "/profile/setPassword";
export const checkPassword = "/profile/checkPassword";

// 3. FRACX YOUR NFT API
export const createProduct = "/products";

// 4. MARKETPLACE API
export const getExploreProducts = "/products/all";
export const getProductsByFilter = "/products";

// 5. FAVORITE API
export const getFavouriteProducts = "/userFavorites";
export const removeFavouriteProduct = "/userFavorites";
export const addFavouriteProduct = "/userFavorites";

// 6. MY-PORTFOLIO API
export const getOwnedProduct = "/productVaults";
export const getCreatedProduct = "/products";
export const getUserActivity = "/userActivities";

export const getOnSaleProducts = "/products";

// 7. CREATE USER ACTIVITY API
export const createUserActivity = "/userActivities";

// 8. USER WALLETS API
export const getUserWalletInfoById = "/userWallets";
export const createWalletAddress = "/userWallets";

// 9. MY COLLECTION API
export const getCollections = "/collections";
export const getProductsByCollectionId = "/products";
export const createCollection = "/collections";
export const getMyCollections = "/collections";

// 10. BUY FracX AND SPECIFIC PRODUCT DETAILS API
export const getProductDetailsById = "/products";
export const createProductVault = "/productVaults";
export const buyToken = "/products/buyNFTToken";
export const getProductOwner = "/productVaults";

// 11. DASHBOARD API
export const getTrendingProduct = "/dashboard/getTrendingAction";
export const getTopArtist = "/dashboard/getTopArtists";
export const getTopBuyer = "/dashboard/getTopBuyer";
export const getFeaturedProducts = "/products";
export const getSegmintOwners = "/productVaults";
export const getMarketVisitorList = "/dashboard/getMarketVisitor";
export const getSellHistory = "/dashboard/getLastWeekBuyHistoty";

export const twitter = "/twitter";

export const verifyRegisterOTP = "/verifyOTP";
export const sendForgotOTP = "/forgotOTP";
export const resendRegisterOTP = "/resendVerificationCode";
export const resetPassword = "/resetPassword";

export const getproductTour = "/productTour";
export const updateProductTour = "/productTour";

// verify FracX by Admin
export const getProductVerified = "/admin/productVerified";
export const updateProductVerified = "/admin/updateProductVerified";
