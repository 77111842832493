import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isGeoSupported: null,
};

export const site = createSlice({
  name: "site",
  initialState,
  reducers: {
    setIsGeoSupported: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.isGeoSupported = action.payload;
      //   console.log("state", action);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsGeoSupported } = site.actions;

export default site.reducer;
