import axios from "axios";
import { errorMsgHandler } from "../../responseMsg/messages";
import AuthService from "../../Service/AuthService";
import ApiService from "../../Service/ApiService";
import {
  getAuth,
  TwitterAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

import { initializeApp } from "firebase/app";
import { twitter, googleLogin, googleSignUp } from "../apis";

export const getConfig = () => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return config;
};
const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const authDomain = process.env.REACT_APP_FIREBASE_AUTHDOMAIN;
const projectId = process.env.REACT_APP_FIREBASE_PROJECTID;
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGEBUCKET;
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID;
const appId = process.env.REACT_APP_FIREBASE_APPID;
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENTID;

const firebaseConfig = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};
initializeApp(firebaseConfig);
const firebaseAuth = getAuth();

export const userSignup = async (path, body) => {
  const config = getConfig();
  const result = await axios
    .post(`${process.env.REACT_APP_BACKEND_BASE_URL}${path}`, body, config)
    .then((res) => {
      console.log("user", res);
      const result = {
        status: true,
        message: "User registered successfully!",
      };
      return result;
    })
    .catch((error) => {
      console.log("error", error.response);
      if (error.response.data.errors.msg[0].msg) {
        const msg = errorMsgHandler(error.response.data.errors.msg[0].msg);
        const result = {
          status: false,
          message: msg,
        };
        return result;
      }
      const msg = errorMsgHandler(error.response.data.errors.msg);
      const result = {
        status: false,
        message: msg,
      };
      // const msg = errorMsgHandler(err.response.data.errors.msg);
      // const result = {
      //   status: false,
      //   message: msg,
      // };
      return result;
    });

  return result;
};

export const userLogin = async (path, body) => {
  const authService = AuthService.get();
  const config = getConfig();
  const result = await axios
    .post(`${process.env.REACT_APP_BACKEND_BASE_URL}${path}`, body, config)
    .then((res) => {
      const token = {
        accessToken: res.data.token,
        refreshToken: res.data.refreshToken,
        accessExp: res.data.expiry,
        refreshExp: res.data.refreshExpiry,
      };
      const authUser = res.data.user;
      authService.login(token, authUser);
      const result = {
        status: true,
        message: "Login Successfully!",
      };
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      if (error.response.data.errors.msg[0].msg) {
        const msg = errorMsgHandler(error.response.data.errors.msg[0].msg);
        const result = {
          status: false,
          message: msg,
        };
        return result;
      }
      const msg = errorMsgHandler(error.response.data.errors.msg);
      const result = {
        status: false,
        message: msg,
      };
      return result;
    });

  return result;
};

export const clearDynamicCache = () => {
  localStorage.removeItem("dynamic_project_settings");
  localStorage.removeItem("dynamic_networks");
  localStorage.removeItem("dynamic_connected_wallet_connector");
  localStorage.removeItem("dynamic_nonce");
  localStorage.removeItem("dynamic_primary_wallet_id");
  localStorage.removeItem("dynamic_authentication_token");
  localStorage.removeItem("dynamic_authenticated_user");
};

export const authDynamic = async (authToken) => {
  const authService = AuthService.get();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/authDynamic`;
  const requestBody = {
    authToken: authToken,
  };
  try {
    const response = await axios.post(url, requestBody);
    if (response.data.status) {
      const token = {
        accessToken: response.data.token,
        refreshToken: response.data.refreshToken,
        accessExp: response.data.expiry,
        refreshExp: response.data.refreshExpiry,
      };
      const authUser = response.data.user;
      authService.login(token, authUser);
      return {
        status: true,
        message: "Login successful!",
      };
    }
    return {
      isUserNotRegistered: +response?.status === 204 ? true : false,
      status: false,
      message: response.data.message,
    };
  } catch (error) {
    console.log(error);
    clearDynamicCache();
    return {
      status: false,
      message: error?.message
        ? error.message
        : "An error occured while trying to log in!",
    };
  }
};

export const userSignupWithDynamic = async (body) => {
  try {
    const authService = AuthService.get();
    let result = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/users/onBoard`,
      body
    );
    if (result.data.status) {
      const token = {
        accessToken: result.data.token,
        refreshToken: result.data.refreshToken,
        accessExp: result.data.expiry,
        refreshExp: result.data.refreshExpiry,
      };
      const authUser = result.data.user;
      authService.login(token, authUser);
      return {
        status: true,
        message: result.data.message,
      };
    }
    clearDynamicCache();
    return {
      status: false,
      message: result.data.message,
    };
  } catch (error) {
    clearDynamicCache();
    return {
      status: false,
      message: error?.response?.data?.errors?.msg
        ? error?.response?.data?.errors?.msg
        : error?.message
        ? error.message
        : "An error occurred while trying to register!",
    };
  }
};

export const forgetPasswordFn = async (path, body) => {
  // const authService = AuthService.get();
  const config = getConfig();
  const result = await axios
    .post(`${process.env.REACT_APP_BACKEND_BASE_URL}${path}`, body, config)
    .then((res) => {
      console.log("forgetPass", res);
      return {
        status: true,
        message: "Mail is sent in your email",
      };
    })
    .catch((err) => {
      console.log("error", err);
      return {
        status: false,
        message: "Email is not exits",
      };
    });

  return result;
};

export const twitterlogin = async () => {
  const provider = new TwitterAuthProvider();

  //provider.addScope('user_sfullname');

  provider.setCustomParameters({
    display: "popup",
  });

  return await signInWithPopup(firebaseAuth, provider)
    .then(async (result) => {
      console.log(result);
      const user = result.user.providerData[0].uid;
      console.log(user);
      const userData = await ApiService.get(`${twitter}/${user}`);
      if (userData.status == 200) {
        const userName = userData.data;
        console.log(userName, " >>>>>This is username");
        return "https://twitter.com/" + userName;
      }
      return "USER_NOT_FOUND";
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
export const signUpGoogle = async () => {
  const authService = AuthService.get();
  const config = getConfig();

  const provider = new GoogleAuthProvider();

  //provider.addScope('user_sfullname');

  provider.setCustomParameters({
    display: "popup",
  });
  const auth = getAuth();
  const signIn = await signInWithPopup(auth, provider)
    .then((result) => {
      console.log(result);
      const user = result.user;
      return {
        success: true,
        token: user.accessToken,
        firstName: result._tokenResponse.firstName,
        lastName: result._tokenResponse.lastName,
      };
    })
    .catch((error) => {
      console.log(error);
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log(credential);
      return { success: false };
    });

  if (signIn.success == true) {
    const body = {
      token: signIn.token,
      firstName: signIn.firstName,
      lastName: signIn.lastName,
    };
    console.log(body);
    const result = await axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}${googleSignUp}`, body, config)
      .then((res) => {
        // console.log("loginUser", res);
        const token = {
          accessToken: res.data.token,
          refreshToken: res.data.refreshToken,
          accessExp: res.data.expiry,
          refreshExp: res.data.refreshExpiry,
        };
        // const authToken = res.data.token;
        const authUser = res.data.user;
        authService.login(token, authUser);
        const result = {
          status: true,
          message: "Login Successfully!",
        };
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response.data.errors.msg[0].msg) {
          const msg = errorMsgHandler(error.response.data.errors.msg[0].msg);
          const result = {
            status: false,
            message: msg,
          };
          return result;
        }
        const msg = errorMsgHandler(error.response.data.errors.msg);
        const result = {
          status: false,
          message: msg,
        };
        return result;
      });
    return result;
  }
  return {
    status: false,
    message: "Something went Wrong",
  };
};

export const singInGoogle = async () => {
  const authService = AuthService.get();
  const config = getConfig();

  const provider = new GoogleAuthProvider();

  //provider.addScope('user_sfullname');

  provider.setCustomParameters({
    display: "popup",
  });
  const auth = getAuth();
  const signIn = await signInWithPopup(auth, provider)
    .then((result) => {
      console.log(result);
      const user = result.user;
      return {
        success: true,
        token: user.accessToken,
        firstName: result._tokenResponse.firstName,
        lastName: result._tokenResponse.lastName,
      };
    })
    .catch((error) => {
      console.log(error);
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log(credential);
      return { success: false };
    });

  if (signIn.success == true) {
    const body = {
      token: signIn.token,
      firstName: signIn.firstName,
      lastName: signIn.lastName,
    };
    console.log(body);
    const result = await axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}${googleLogin}`, body, config)
      .then((res) => {
        // console.log("loginUser", res);
        const token = {
          accessToken: res.data.token,
          refreshToken: res.data.refreshToken,
          accessExp: res.data.expiry,
          refreshExp: res.data.refreshExpiry,
        };
        // const authToken = res.data.token;
        const authUser = res.data.user;
        authService.login(token, authUser);
        const result = {
          status: true,
          message: "Login Successfully!",
        };
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        if (error.response.data.errors.msg[0].msg) {
          const msg = errorMsgHandler(error.response.data.errors.msg[0].msg);
          const result = {
            status: false,
            message: msg,
          };
          return result;
        }
        const msg = errorMsgHandler(error.response.data.errors.msg);
        const result = {
          status: false,
          message: msg,
        };
        return result;
      });
    return result;
  }
  return {
    status: false,
    message: "Something went Wrong",
  };
};
export const refreshTokenFn = async (path, body) => {
  // const authService = AuthService.get();
  // const config = getConfig();
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  // console.log("tt", body.refreshToken);
  const result = await axios
    .post(`${process.env.REACT_APP_BACKEND_BASE_URL}${path}`, body, config)
    .then((res) => {
      // console.log("refres", res);
      const token = {
        accessToken: res.data.token,
        refreshToken: res.data.refreshToken,
        accessExp: res.data.expiry,
        refreshExp: res.data.refreshExpiry,
      };
      // const authToken = res.data.token;
      const user = res.data.user;
      return {
        status: true,
        token: token,
        user: user,
      };
    })
    .catch((err) => {
      console.log("error", err);
      return {
        status: false,
      };
    });

  return result;
};
