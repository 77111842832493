export const errorMessageHandler = (obj) => {
  if (obj.status === 404) {
    return "Not found";
  } else if (obj.status === 422) {
    return "Already exits";
  }
};

export const getDay = (days) => {
  const x = [];
  days.forEach((day) => {
    if (day === 0) {
      x.push("Mon");
    } else if (day === 1) {
      x.push("Tue");
    } else if (day === 2) {
      x.push("Wed");
    } else if (day === 3) {
      x.push("Thurs");
    } else if (day === 4) {
      x.push("Fri");
    } else if (day === 5) {
      x.push("Sat");
    } else {
      x.push("Sun");
    }
  });
  return x;
};

export const getMonth = (month) => {
  console.log("month", month);
  var x = "";
  if (month == 0) {
    x = "Jan";
  } else if (month == 1) {
    x = "Feb";
  } else if (month == 2) {
    x = "Mar";
  } else if (month == 3) {
    x = "Apr";
  } else if (month == 4) {
    x = "May";
  } else if (month == 5) {
    x = "Jun";
  } else if (month == 6) {
    x = "Jul";
  } else if (month == 7) {
    x = "Aug";
  } else if (month == 8) {
    x = "Sep";
  } else if (month == 9) {
    x = "Oct";
  } else if (month == 10) {
    x = "Nov";
  } else {
    x = "Dec";
  }
  return x;
};

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export default formatBytes;

export function onKeyUpHandler(
  myInput,
  letter,
  capital,
  number,
  length,
  special
) {
  var lowerCaseLetters = /[a-z]/g;
  if (myInput.match(lowerCaseLetters)) {
    letter.classList.remove("invalid");
    letter.classList.add("valid");
  } else {
    letter.classList.remove("valid");
    letter.classList.add("invalid");
  }

  // Validate capital letters
  var upperCaseLetters = /[A-Z]/g;
  if (myInput.match(upperCaseLetters)) {
    capital.classList.remove("invalid");
    capital.classList.add("valid");
  } else {
    capital.classList.remove("valid");
    capital.classList.add("invalid");
  }

  // Validate numbers
  var numbers = /[0-9]/g;
  if (myInput.match(numbers)) {
    number.classList.remove("invalid");
    number.classList.add("valid");
  } else {
    number.classList.remove("valid");
    number.classList.add("invalid");
  }

  // Validate length
  if (myInput.length >= 8) {
    length.classList.remove("invalid");
    length.classList.add("valid");
  } else {
    length.classList.remove("valid");
    length.classList.add("invalid");
  }
  // Validate special characters
  var specialChar = /[!@#$%^&*]/;
  if (myInput.match(specialChar)) {
    special.classList.remove("invalid");
    special.classList.add("valid");
  } else {
    special.classList.remove("valid");
    special.classList.add("invalid");
  }
}
