import React from "react";
import notFound from "../../assets/no_result/no-results.png";

function NoData({ info }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "24px 0px",
      }}
    >
      <img
        src={notFound}
        alt="banner"
        className="max-[630px]:w-[100px] max-[630px]:h-[100px] max-[950px]:w-[150px] max-[950px]:h-[150px] w-[200px] h-[200px] lg:h-full"
      />
      <p className="mt-4 text-center max-[430px]:text-sm max-[630px]:text-md max-[930px]:text-lg text-xl">
        {info}
      </p>
    </div>
  );
}

export default NoData;
