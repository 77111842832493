import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DynamicConnectButton,
} from "@dynamic-labs/sdk-react";
import { toast } from "react-toastify";
import {
  userSignupWithDynamic,
} from "../../../apis/authApis/authApis";
import ModalCom from "../../Helpers/ModalCom";
import Icons from "../../Helpers/Icons";
import InputCom from "../../Helpers/Inputs/InputCom";

function LoginWithDynamicxyz() {
  const navigate = useNavigate();
  const [disabledBtn, setDisabledBtn] = React.useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [authToken, setAuthToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [modelShow, setModelShow] = useState(false);

  useEffect(() => {
    if (!firstName || !lastName || !email || !userName) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }
  }, [email, firstName, lastName]);

  const children = (
    <div
      className=" w-full btn-login rounded-[50px] mb-6 text-xl text-white font-bold flex justify-center bg-button-backgroud-color items-center"
      data-testid="exampleChild"
    >
      {loading ? (
        <div className="signup btn-loader"></div>
      ) : (
        "Sign In With Wallet"
      )}
    </div>
  );

  const doSignUp = async () => {
    try {
      setLoading(true);
      const body = {
        firstName,
        lastName,
        userName,
        email,
        authToken,
      };
      let result = await userSignupWithDynamic(body);
      if (result.status) {
        navigate("/");
      }
      setLoading(false);
      toast.success(
        result.message.indexOf("email_1 dup key") > -1
          ? "Email is already registered"
          : result.message
      );
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const getAuthToken = localStorage.getItem("dynamic_authentication_token");
    setAuthToken(getAuthToken);
  }, []);
  return (
    <div className="text-center">
      
      <DynamicConnectButton>{children}</DynamicConnectButton>
      {modelShow && (
        <ModalCom>
          <div className="flex flex-col lg:w-[500px] h-[600px] w-full bg-[#fff] lg:rounded-2xl p-6">
            <div className="flex justify-end">
              <span
                className="cursor-pointer"
                onClick={() => {
                  setModelShow(false);
                }}
              >
                <Icons name="cross-icon" />
              </span>
            </div>
            <div className="w-full">
              <form className="px-6 pt-6 pb-6">
                <div className="mb-4 text-left">
                  <InputCom
                    value={firstName}
                    placeholder="First Name"
                    label="First Name"
                    name="first_name"
                    type="text"
                    iconName="people"
                    inputHandler={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="text-left mb-4">
                  <InputCom
                    value={lastName}
                    placeholder="Last Name"
                    label="Last Name"
                    name="Last_name"
                    type="text"
                    iconName="people"
                    inputHandler={(e) => setlastName(e.target.value)}
                  />
                </div>
                <div className="text-left mb-6">
                  <InputCom
                    value={userName}
                    placeholder="User Name"
                    label="User Name"
                    name="userName"
                    type="userName"
                    iconName="people"
                    inputHandler={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div className="text-left mb-4">
                  <InputCom
                    value={email}
                    placeholder="example@domain.com"
                    label="Email Address"
                    name="email"
                    type="email"
                    iconName="message"
                    inputHandler={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="flex items-center justify-end h-[58px] overflow-hidden">
                  {disabledBtn ? (
                    <button
                      type="submit"
                      disabled={true}
                      className="bg-slate-100 disabled:cursor-not-allowed shadow-none text-slate-400 font-bold py-2 px-4 rounded"
                    >
                      Sign Up
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled={loading}
                      className="bg-button-backgroud-color text-white font-bold py-2 px-4 rounded"
                      onClick={doSignUp}
                    >
                      {loading ? (
                        <div className="signup btn-loader"></div>
                      ) : (
                        "Sign Up"
                      )}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </ModalCom>
      )}
    </div>
  );
}

export default LoginWithDynamicxyz;
