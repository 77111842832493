import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import titleShape from "../../../assets/images/shape/text-shape-three.svg";
import AuthLayout from "../AuthLayout";
import Otp from "./Otp";

export default function VerifyYou() {
  const navigate = useNavigate();
  const [isOtpFilled, setIsOtpFilled] = useState(false);
  // const [disabledBtn, setDisabledBtn] = React.useState(true);
  // const [otp, setOtp] = useState();
  // useEffect(() => {
  //   if (!otp) {
  //     setDisabledBtn(true);
  //   } else {
  //     setDisabledBtn(false);
  //   }
  // }, [otp]);

  const onContinue = () => {
    navigate("/update-password");
  };
  return (
    <>
      <AuthLayout
        slogan="FracX"
      >
        <form onSubmit={onContinue} className="h-full w-full flex-1">
          <div className="content-wrapper xl:bg-white w-full sm:w-auto px-5 xl:px-[70px] 2xl:px-[100px] h-full rounded-xl flex flex-col justify-center">
            <div className="title-area flex flex-col justify-center items-center relative text-center mb-8">
              <h1 className="sm:text-5xl text-4xl font-bold leading-[74px] text-dark-gray">
                Verification Code
              </h1>
              <div className="shape sm:w-[377px] w-[270px] -mt-5 ml-5">
                <img src={titleShape} alt="shape" />
              </div>
            </div>
            <div className="input-area">
              <Otp setIsOtpFilled={setIsOtpFilled} />
              <div className="signin-area mb-3.5">
                {!isOtpFilled ? (
                  <button
                    type="submit"
                    className="disabled:cursor-not-allowed w-full rounded-[50px] h-[58px] mb-6 text-xl text-slate-400 font-bold flex justify-center bg-slate-100 items-center"
                    disabled
                  >
                    Continue
                  </button>
                ) : (
                  <button
                    disabled={false}
                    type="submit"
                    className="w-full rounded-[50px] h-[58px] mb-6 text-xl text-white font-bold flex justify-center bg-purple items-center"
                  >
                    Continue
                  </button>
                )}
              </div>
              <div className="resend-code flex justify-center">
                <p className="text-lg text-thin-light-gray font-normal">
                  Dont’t have an account?
                  <a href="#" className="ml-2 text-purple font-medium">
                    Please resend
                  </a>
                </p>
              </div>
            </div>
          </div>
        </form>
      </AuthLayout>
    </>
  );
}
