import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Login from "../components/AuthPages/Login";

function LoginPage() {
  const navigate = useNavigate();
  const isLogin = localStorage.getItem("token");
  useEffect(() => {
    if (isLogin) {
      navigate("/");
    }
  }, [isLogin]);

  return <>{isLogin ? null : <Login />}</>;
}

export default LoginPage;
