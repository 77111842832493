import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import titleShape from "../../../assets/images/shape/text-shape-three.svg";
import AuthLayout from "../AuthLayout";
import Otp from "./Otp";
import ApiService from "../../../Service/ApiService";
import { resetPassword, sendForgotOTP } from "../../../apis/apis";
import { toast } from "react-toastify";
import InputCom from "../../Helpers/Inputs/InputCom";
import { errorMsgHandler } from "../../../responseMsg/messages";
import Icons from "../../Helpers/Icons";
import MessageBox from "../../MessageBox/messageBox";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [isOtpFilled, setIsOtpFilled] = useState(false);
  const [otpFilled, setOtp] = useState(null);
  const [repassword, setRepassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState("");
  const [validation, setValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBtnDisabled, setisBtnDisabled] = useState(false);
  const [isResendBtnDisabled, setIsResendBtnDisabled] = useState(false);
  const [strongPassMsg, setStrongPassMsg] = useState("");
  const [isOtpResend, setIsOtpResend] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const { email } = useParams();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, isOtpResend]);

  const getUserEmail = () => {
    if (email) {
      return email;
    }
    const user = localStorage.getItem("user");
    if (user) {
      return JSON.parse(user).email;
    }
    return sessionStorage.getItem("email");
  };

  const handlenewpassword = (e) => {
    var regularExpression =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,100}$/;
    if (!regularExpression.test(e.target.value)) {
      setStrongPassMsg("Password must be strong.");
    } else {
      setStrongPassMsg("");
    }
    setNewpassword(e.target.value);
  };

  const handlerepassword = (e) => {
    setRepassword(e.target.value);
  };

  useEffect(() => {
    console.log(loading , ' ', isOtpFilled, ' ', repassword, ' ', newpassword, ' ', strongPassMsg);
    if (
      loading ||
      !isOtpFilled ||
      !repassword ||
      !newpassword ||
      strongPassMsg
    ) {
      console.log('setting the btn disabled true');
      setisBtnDisabled(true);
    } else {
      if (repassword !== newpassword) {
        console.log('setting the btn disabled true');
        setisBtnDisabled(true);
      } else {
        console.log('setting the btn disabled false');
        setisBtnDisabled(false);
      }
    }
  }, [isOtpFilled, repassword, newpassword, loading, strongPassMsg]);

  const onContinue = (e) => {
    e.preventDefault();
    setLoading(true);
    if (newpassword !== repassword) {
      setValidation("Password and confirm password do not match");
      setLoading(false);
    } else {
      const email = getUserEmail();
      const body = { email: email, otp: otpFilled, password: newpassword };
      ApiService.post(resetPassword, body)
        .then((res) => {
          if (res.data.success == false) {
            toast.warn("Incorrect OTP");
            setLoading(false);
          } else {
            toast.success("Password Reset Successfully");
            setTimeout(() => {
              navigate("/login");
              setLoading(false);
            }, [3000]);
          }
        })
        .catch((error) => {
          if (error.response.data.errors.msg[0].msg) {
            const msg = errorMsgHandler(error.response.data.errors.msg[0].msg);
            toast.error(msg);
          } else {
            const msg = errorMsgHandler(error.response.data.errors.msg);
            toast.error(msg);
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (newpassword && repassword && repassword !== newpassword) {
      setValidation("Password and confirm password do not match!");
    } else {
      setValidation("");
    }
  }, [newpassword, repassword]);

  useEffect(() => {
    if (!repassword) {
      setValidation("");
    }
    if (!newpassword) {
      setStrongPassMsg("");
    }
  }, [repassword, newpassword]);

  const resendOTP = () => {
    const email = getUserEmail();
    const body = { email: email };
    setIsResendBtnDisabled(true);
    ApiService.post(sendForgotOTP, body)
      .then((res) => {
        if (res.data.success == false) {
          toast.error("Invalid Request");
          setIsResendBtnDisabled(false);
        } else {
          setMinutes(1);
          setSeconds(0);
          setIsOtpResend(!isOtpResend);
          setIsResendBtnDisabled(false);
          toast.success("OTP send Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
        setIsResendBtnDisabled(false);
      });
  };

  return (
    <>
      <AuthLayout
        slogan="FracX"
      >
        <form onSubmit={onContinue} className="h-full w-full flex-1">
          <div className="content-wrapper xl:bg-white w-full sm:w-auto px-5 xl:px-[70px] 2xl:px-[100px] h-full rounded-xl flex flex-col justify-center">
            <div className="title-area flex flex-col justify-center items-center relative text-center mb-8">
              <h1 className="sm:text-5xl text-4xl font-bold leading-[74px] text-dark-gray">
                Verification Code
              </h1>
              <div className="shape sm:w-[377px] w-[270px] -mt-5 ml-5">
                <img
                  src={titleShape}
                  alt="shape"
                  style={{ filter: "hue-rotate(300deg)" }}
                />
              </div>
            </div>
            <div className="input-area">
              <Otp setIsOtpFilled={setIsOtpFilled} setOtp={setOtp} />
              <div className="input-item mb-5 relative">
                <InputCom
                  value={newpassword}
                  placeholder="*********"
                  label="New Password"
                  name="password"
                  id="newPassId"
                  isPass={true}
                  letterId="newPassLetterId"
                  capitalId="newPassCapitalId"
                  numberId="newPassNumberId"
                  lengthId="newPassLengthId"
                  specialId="newPassSpecialId"
                  type={!showPass ? "password" : "text"}
                  iconName="password"
                  inputHandler={handlenewpassword}
                  setShowPass={setShowPass}
                  showPass={showPass}
                />
                <MessageBox
                  id="newPassId"
                  letterId="newPassLetterId"
                  capitalId="newPassCapitalId"
                  numberId="newPassNumberId"
                  lengthId="newPassLengthId"
                  specialId="newPassSpecialId"
                />
              </div>
              <div className="input-item mb-5 relative">
                <InputCom
                  isValidated={validation}
                  value={repassword}
                  placeholder="*********"
                  label="Re-enter Password"
                  name="password"
                  id="confirmPassId"
                  isPass={true}
                  letterId="confirmPassLetterId"
                  capitalId="confirmPassCapitalId"
                  numberId="confirmPassNumberId"
                  lengthId="confirmPassLengthId"
                  specialId="confirmPassSpecialId"
                  type={!showConfirmPass ? "password" : "text"}
                  iconName="password"
                  inputHandler={handlerepassword}
                  setShowPass={setShowConfirmPass}
                  showPass={showConfirmPass}
                />
                {validation ? (
                  <div
                    className="error-message"
                    style={{ fontSize: "14px", marginTop: "6px" }}
                  >
                    {validation}
                  </div>
                ) : null}
                <MessageBox
                  id="confirmPassId"
                  letterId="confirmPassLetterId"
                  capitalId="confirmPassCapitalId"
                  numberId="confirmPassNumberId"
                  lengthId="confirmPassLengthId"
                  specialId="confirmPassSpecialId"
                />
              </div>
              <div className="signin-area my-6">
                {isBtnDisabled ? (
                  <button
                    type="submit"
                    className="disabled:cursor-not-allowed w-full rounded-[50px] h-[58px] text-xl text-slate-400 font-bold flex justify-center bg-slate-100 items-center"
                    disabled
                  >
                    {loading ? "Loading..." : "Continue"}
                  </button>
                ) : (
                  <button
                    disabled={false}
                    type="submit"
                    className="w-full rounded-[50px] h-[58px] text-xl text-white font-bold flex justify-center bg-button-backgroud-color items-center"
                  >
                    Continue
                  </button>
                )}
              </div>
              <div className="resend-code flex justify-end">
                {seconds > 0 || minutes > 0 ? (
                  <span className="flex justify-center items-center">
                    <span className="">
                      <Icons name="timer-icon" color="var(--icon-color)" />
                    </span>
                    <span className="px-[8px] text-tertiary-color">
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds} min
                    </span>
                  </span>
                ) : (
                  ""
                )}
                <button
                  disabled={minutes || seconds || isResendBtnDisabled}
                  type="button"
                  className={
                    minutes || seconds || isResendBtnDisabled
                      ? "text-slate-400 font-medium"
                      : "text-button-backgroud-color font-medium"
                  }
                  onClick={() => {
                    resendOTP();
                  }}
                >
                  Resend
                </button>
              </div>
            </div>
          </div>
        </form>
      </AuthLayout>
    </>
  );
}
