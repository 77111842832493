import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const APIURL = process.env.REACT_APP_TRANSACTION_REWARD;
export const queryGraph = async () => {
  const tokensQuery = `
  query {
  rewards(first: 5) {
    id
    to
    amount
    timestamp
  }
}
`;

  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  client
    .query({
      query: gql(tokensQuery),
    })
    .then((data) => console.log("Subgraph data: ", data))
    .catch((err) => {
      console.log("Error fetching data: ", err);
    });
};

export const getRewardsForWallet = async (wallet) => {
  try {
    console.log("Fetcing data from graphql");
    const tokensQuery = `
  query {
  rewards(where: {to: "${wallet.toLowerCase()}",amount_gt: 0 }) {
    id
    to
    amount
    timestamp
    blockNumber
  }
}
`;

    const client = new ApolloClient({
      uri: APIURL,
      cache: new InMemoryCache(),
    });

    return client
      .query({
        query: gql(tokensQuery),
      })
      .then((data) => {
        console.log("Subgraph data: ", data);
        return data;
      })
      .catch((err) => {
        console.log("Error fetching data: ", err);
      });
  } catch (err) {
    console.log(err);
  }
};

export const getPendingProposals = async (contractAddress, chainId) => {
  try {
    console.log("Fetcing data from graphql", contractAddress);
    const proposalsQuery = `
  query {
 signerProposalCreateds(where: { contractAddress: "${contractAddress.toLowerCase()}" }) {
        id
        proposer
        proposalType
        signerProposalCount
        signerModified
        revoked
        approved
        blockTimestamp
        blockNumber
        contractAddress
        expiration
        signerModified
        timestamp
        transactionHash
        signers {
          id
          contractAddress
          approved
          proposalId
          wallet
        }
      }
}
`;

    let uri =
      chainId == 43113
        ? process.env.REACT_APP_MULTISIG_GRAPH_URL
        : process.env.REACT_APP_MULTISIG_GRAPH_URL_POLYGON;
    const client = new ApolloClient({
      uri: uri,
      cache: new InMemoryCache(),
    });

    return client
      .query({
        query: gql(proposalsQuery),
      })
      .then((data) => {
        console.log("Subgraph data: ", data);
        return data;
      })
      .catch((err) => {
        console.log("Error fetching data: ", err);
      });
  } catch (err) {
    console.log(err);
  }
};

export const getPendingMinSigProposals = async (contractAddress, chainId) => {
  try {
    console.log("Fetcing data from graphql", contractAddress);
    const proposalsQuery = `
      query {

      minSignatureProposalCreateds(where: { contractAddress: "${contractAddress.toLowerCase()}" }) {
          approved
          blockNumber
          blockTimestamp
          contractAddress
          id
          expiration
          minSignatures
          proposalID
          revoked
          proposer
          transactionHash
          timestamp
          signers {
            approved
            contractAddress
            id
            proposalId
            wallet
          }
        }
      }
`;

    let uri =
      chainId == 43113
        ? process.env.REACT_APP_MULTISIG_GRAPH_URL
        : process.env.REACT_APP_MULTISIG_GRAPH_URL_POLYGON;
    const client = new ApolloClient({
      uri: uri,
      cache: new InMemoryCache(),
    });

    return client
      .query({
        query: gql(proposalsQuery),
      })
      .then((data) => {
        console.log("Subgraph data: ", data);
        return data;
      })
      .catch((err) => {
        console.log("Error fetching data: ", err);
      });
  } catch (err) {
    console.log(err);
  }
};

export const getAllproposal = async (contractAddress, chainId) => {
  try {
    let minSignatureProposals = await getPendingMinSigProposals(
      contractAddress,
      chainId
    );
    console.log(minSignatureProposals);
    minSignatureProposals =
      minSignatureProposals.data.minSignatureProposalCreateds;

    let proposals = await getPendingProposals(contractAddress, chainId);
    proposals = proposals?.data?.signerProposalCreateds;

    let lockProposals = await getLockOrUnlockProposalsOfMultiSig(
      contractAddress,
      chainId
    );
    lockProposals = lockProposals?.data?.lockOrUnLockProposalCreateds;
    // Step 3: Create an array to store the combined data
    const combinedData = [];

    // Step 4: Extract the required fields and populate the combinedData array
    if (minSignatureProposals) {
      for (const minSignatureProposal of minSignatureProposals) {
        const combinedProposal = {};

        combinedProposal.serialNumber = combinedData.length + 1;
        combinedProposal.proposalType = "Update Min Signature";
        combinedProposal.status = minSignatureProposal.approved
          ? "Executed"
          : minSignatureProposal.revoked
          ? "Revoked"
          : "Pending";
        combinedProposal.approvedVotesBySigner =
          minSignatureProposal.signers.filter(
            (signer) => signer.approved
          ).length;
        combinedProposal.proposalId = minSignatureProposal.proposalID;
        combinedProposal.signers = minSignatureProposal.signers;
        combinedProposal.newMinSignature = minSignatureProposal.minSignatures;
        combinedProposal.proposer = minSignatureProposal.proposer;
        combinedProposal.contractAddress = minSignatureProposal.contractAddress;
        combinedProposal.expiration = minSignatureProposal.expiration;
        combinedData.push(combinedProposal);
      }
    }
    if (proposals) {
      for (const proposal of proposals) {
        const combinedProposal = {};

        combinedProposal.serialNumber = combinedData.length + 1;
        combinedProposal.proposalType =
          proposal.proposalType == 0 ? "Add Signer" : "Remove Signer";
        combinedProposal.status = proposal.approved
          ? "Executed"
          : proposal.revoked
          ? "Revoked"
          : "Pending";
        combinedProposal.approvedVotesBySigner = proposal.signers.filter(
          (signer) => signer.approved
        ).length;
        combinedProposal.signers = proposal.signers;
        combinedProposal.proposalId = proposal.signerProposalCount;
        combinedProposal.modifiedSigner = proposal.signerModified;
        combinedProposal.proposer = proposal.proposer;
        combinedProposal.contractAddress = proposal.contractAddress;
        combinedProposal.expiration = proposal.expiration;
        combinedData.push(combinedProposal);
      }
    }

    if (lockProposals) {
      for (const proposal of lockProposals) {
        const combinedProposal = {};

        combinedProposal.serialNumber = combinedData.length + 1;
        combinedProposal.proposalType = proposal.proposalType;
        combinedProposal.status = proposal.approved
          ? "Executed"
          : proposal.revoked
          ? "Revoked"
          : "Pending";
        combinedProposal.approvedVotesBySigner = proposal.signers.filter(
          (signer) => signer.approved
        ).length;
        combinedProposal.signers = proposal.signers;
        combinedProposal.proposalId = proposal.proposalID;
        combinedProposal.proposer = proposal.proposer;
        combinedProposal.contractAddress = proposal.contractAddress;
        combinedProposal.vaultContractAddress = proposal.vaultContractAddress;
        combinedProposal.expiration = proposal.expiration;
        combinedData.push(combinedProposal);
      }
    }

    console.log(combinedData, "{{{{{GRAPHQL}}}}}");
    return combinedData;
  } catch (err) {
    console.log(err);
  }
};

export const getLockOrUnlockProposalsOfMultiSig = async (
  contractAddress,
  chainId
) => {
  try {
    console.log("Fetcing data from graphql", contractAddress);
    const proposalsQuery = `
      query {
          lockOrUnLockProposalCreateds(where: { contractAddress: "${contractAddress.toLowerCase()}" }) {
            approved
            blockNumber
            blockTimestamp
            contractAddress
            data
            expiration
            id
            proposalID
            proposalType
            proposer
            revoked
            timestamp
            transactionHash
            vaultContractAddress
            signers {
              approved
              contractAddress
              id
              wallet
              proposalId
            }
          }
      }
`;

    let uri =
      chainId == 43113
        ? process.env.REACT_APP_MULTISIG_GRAPH_URL
        : process.env.REACT_APP_MULTISIG_GRAPH_URL_POLYGON;
    const client = new ApolloClient({
      uri: uri,
      cache: new InMemoryCache(),
    });

    return client
      .query({
        query: gql(proposalsQuery),
      })
      .then((data) => {
        console.log("Subgraph data: ", data);
        return data;
      })
      .catch((err) => {
        console.log("Error fetching data: ", err);
      });
  } catch (err) {
    console.log(err);
  }
};

export const getLockOrUnlockProposalsofVault = async (
  vaultContractAddress,
  chainId
) => {
  try {
    console.log("Fetcing data from graphql", vaultContractAddress);
    const proposalsQuery = `
      query {
          lockedContracts(where:{vaultContractAddress: "${vaultContractAddress.toLowerCase()}", active: true}){
            id
            contractAddress
            vaultContractAddress
            active
          }
      }
`;
    let uri =
      chainId == 43113
        ? process.env.REACT_APP_MULTISIG_GRAPH_URL
        : process.env.REACT_APP_MULTISIG_GRAPH_URL_POLYGON;
    const client = new ApolloClient({
      uri: uri,
      cache: new InMemoryCache(),
    });

    return client
      .query({
        query: gql(proposalsQuery),
      })
      .then((data) => {
        console.log("Subgraph data: ", data);
        return data;
      })
      .catch((err) => {
        console.log("Error fetching data: ", err);
      });
  } catch (err) {
    console.log(err);
  }
};

export const getLockOrUnlockProposalsOfMultiSigByProposalId = async (
  contractAddress,
  proposalId,
  chainId
) => {
  try {
    console.log("Fetcing data from graphql", contractAddress);
    const proposalsQuery = `
      query {
          lockOrUnLockProposalCreateds(where: { contractAddress: "${contractAddress.toLowerCase()}" proposalID: ${proposalId} }) {
            approved
            blockNumber
            blockTimestamp
            contractAddress
            data
            expiration
            id
            proposalID
            proposalType
            proposer
            revoked
            timestamp
            transactionHash
            vaultContractAddress
            signers {
              approved
              contractAddress
              id
              wallet
              proposalId
            }
          }
      }
`;

    let uri =
      chainId == 43113
        ? process.env.REACT_APP_MULTISIG_GRAPH_URL
        : process.env.REACT_APP_MULTISIG_GRAPH_URL_POLYGON;
    const client = new ApolloClient({
      uri: uri,
      cache: new InMemoryCache(),
    });

    return client
      .query({
        query: gql(proposalsQuery),
      })
      .then((data) => {
        console.log("Subgraph data: ", data);
        return data;
      })
      .catch((err) => {
        console.log("Error fetching data: ", err);
      });
  } catch (err) {
    console.log(err);
  }
};
export const getLockProposal = async (contractAddress, proposalId, chainId) => {
  try {
    let lockProposals = await getLockOrUnlockProposalsOfMultiSigByProposalId(
      contractAddress,
      proposalId,
      chainId
    );
    lockProposals = lockProposals?.data?.lockOrUnLockProposalCreateds;
    // Step 3: Create an array to store the combined data
    const combinedData = [];

    if (lockProposals) {
      for (const proposal of lockProposals) {
        const combinedProposal = {};

        combinedProposal.serialNumber = combinedData.length + 1;
        combinedProposal.proposalType = proposal.proposalType;
        combinedProposal.status = proposal.approved
          ? "Executed"
          : proposal.revoked
          ? "Revoked"
          : "Pending";
        combinedProposal.approvedVotesBySigner = proposal.signers.filter(
          (signer) => signer.approved
        ).length;
        combinedProposal.signers = proposal.signers;
        combinedProposal.proposalId = proposal.proposalID;
        combinedProposal.proposer = proposal.proposer;
        combinedProposal.contractAddress = proposal.contractAddress;
        combinedProposal.vaultContractAddress = proposal.vaultContractAddress;
        combinedData.push(combinedProposal);
      }
    }

    console.log(combinedData, "{{{{{GRAPHQL}}}}}");
    return combinedData;
  } catch (err) {
    console.log(err);
  }
};

export const getLockedContractOfVault = async (
  vaultContractAddress,
  chainId
) => {
  const data = await getLockOrUnlockProposalsofVault(
    vaultContractAddress,
    chainId
  );
  const proposals = data.data.lockedContracts;
  return proposals;
};
