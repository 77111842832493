import React from "react";
import { onKeyUpHandler } from "../../../../utils/utils";
import Icons from "../../Icons";

export default function InputCom({
  label,
  type,
  name,
  placeholder,
  iconName,
  inputHandler,
  value,
  isValidated,
  setShowPass,
  showPass,
  disabled,
  isPass,
  id,
  letterId,
  capitalId,
  numberId,
  lengthId,
  specialId,
}) {
  return (
    <div className="input-com">
      {label && (
        <label
          className="input-label text-dark-gray text-xl font-bold block mb-2.5"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div
        className={
          isValidated && value
            ? "input-wrapper border border-light-red w-full rounded-[50px] h-[58px] overflow-hidden relative opensea-input-main"
            : "input-wrapper border border-light-purple w-full rounded-[50px] h-[58px] overflow-hidden relative opensea-input-main"
        }
      >
        <div className="opensea-input-prefix" />
        <input
          onFocus={() => {
            if (isPass) {
              document.getElementById(id).style.display = "block";
            }
          }}
          onBlur={() => {
            if (isPass) {
              document.getElementById(id).style.display = "none";
            }
          }}
          onKeyUp={() => {
            if (isPass) {
              let letter = document?.getElementById(letterId);
              let capital = document?.getElementById(capitalId);
              let number = document?.getElementById(numberId);
              let length = document?.getElementById(lengthId);
              let special = document?.getElementById(specialId);
              onKeyUpHandler(value, letter, capital, number, length, special);
            }
          }}
          disabled={disabled ? disabled : false}
          placeholder={placeholder}
          value={value}
          onChange={inputHandler}
          // className="input-field placeholder:text-base text-bese px-6 text-dark-gray w-full h-full bg-[#FAFAFA] focus:ring-0 focus:outline-none"
          className="opensea-input"
          type={type}
          id={name}
        />
        {iconName && !showPass ? (
          <div
            className="absolute right-6 bottom-[19px] z-10 cursor-pointer"
            onClick={() => {
              if (showPass !== undefined) {
                setShowPass(!showPass);
              }
            }}
          >
            <Icons name={iconName} />
          </div>
        ) : iconName ? (
          <div
            className="absolute right-6 bottom-[19px] z-10 cursor-pointer"
            style={{ width: "25px" }}
            onClick={() => {
              if (showPass !== undefined) {
                setShowPass(!showPass);
              }
            }}
          >
            <Icons name="unlock" />
          </div>
        ) : null}
      </div>
    </div>
  );
}
