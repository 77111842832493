import React from "react";
import Icons from "./Icons";

export default function SearchCom({
  className,
  inputClasses,
  setSearchPro,
  searchPro,
}) {
  return (
    <div
      className={`w-full h-[48px] pl-2 flex rounded-full overflow-hidden bg-white border-2 border-slate-400 ${
        className || ""
      }`}
    >
      <div
        className="w-6 h-full flex justify-center items-center mr-2.5 text-dark-gray"
        style={{ cursor: "pointer" }}
      >
        <Icons name="deep-search" />
      </div>
      <div className="flex-1 h-full">
        <input
          className={`w-full h-full focus:outline-0 focus:ring-0 ${
            inputClasses || ""
          }`}
          value={searchPro}
          type="text"
          placeholder="Search items, collections..."
          onChange={(e) => {
            // setTimeout(() => {
            setSearchPro(e.target.value);
            // }, 1000);
          }}
        />
      </div>
    </div>
  );
}
