import React, { useEffect, useState } from "react";
import titleShape from "../../../assets/images/shape/title-shape-two.svg";
import InputCom from "../../Helpers/Inputs/InputCom";
import AuthLayout from "../AuthLayout";
import ThankYou from "../ThankYou";

export default function UpdatePassword() {
  const [updated, setValue] = useState(false);
  const [message, setMessage] = useState(false);
  const [repassword, setRepassword] = useState();
  const [newpassword, setNewpassword] = useState();
  const [oldpassword, setOldpassword] = useState();
  const [disabledBtn, setDisabledBtn] = React.useState(true);
  const [showOldPass, setShowOldPass] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [validation, setValidation] = useState(false);

  const updatePassword = () => {
    if (newpassword !== repassword) {
      // alert("aaa")
      setValidation("Password and confirm password do not match");
      return false;
    }
    setValue(!updated);
    setTimeout(() => {
      setMessage(!message);
    }, 100);
  };

  useEffect(() => {
    if (!oldpassword || !newpassword || !repassword) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }
  }, [oldpassword, newpassword, repassword]);

  const handleoldpassword = (e) => {
    setOldpassword(e.target.value);
  };

  const handlenewpassword = (e) => {
    setNewpassword(e.target.value);
  };

  const handlerepassword = (e) => {
    setRepassword(e.target.value);
  };

  return (
    <>
      <AuthLayout
        slogan="FracX"
      >
        <div className="h-full w-full flex-1">
          {updated === false ? (
            <div className="content-wrapper update-password-section xl:bg-white w-full 2xl:h-full xl:h-full sm:w-auto sm:px-[70px] px-5 2xl:px-[100px] rounded-xl flex flex-col justify-center">
              <div>
                <div className="title-area relative flex flex-col justify-center items-center mb-7">
                  <h1 className="sm:text-5xl text-4xl font-bold leading-[74px] text-dark-gray">
                    Update Password
                  </h1>
                  {/* w-[341px] absolute top-14 left-12 */}
                  <div className="shape sm:w-[341px] w-[270px] -mt-5 sm:-mt-1 ml-5">
                    <img src={titleShape} alt="shape" />
                  </div>
                </div>
                <div className="input-area">
                  <div className="input-item mb-5">
                    <InputCom
                      value={oldpassword}
                      placeholder="*********"
                      label="Old Password"
                      name="password"
                      type={!showOldPass ? "password" : "text"}
                      iconName="password"
                      inputHandler={handleoldpassword}
                      setShowPass={setShowOldPass}
                      showPass={showOldPass}
                    />
                  </div>
                  <div className="input-item mb-5">
                    <InputCom
                      value={newpassword}
                      placeholder="*********"
                      label="New Password"
                      name="password"
                      type={!showPass ? "password" : "text"}
                      iconName="password"
                      inputHandler={handlenewpassword}
                      setShowPass={setShowPass}
                      showPass={showPass}
                    />
                  </div>
                  <div className="input-item mb-5">
                    <InputCom
                      isValidated={validation}
                      value={repassword}
                      placeholder="*********"
                      label="Re-enter Password"
                      name="password"
                      type={!showConfirmPass ? "password" : "text"}
                      iconName="password"
                      inputHandler={handlerepassword}
                      setShowPass={setShowConfirmPass}
                      showPass={showConfirmPass}
                    />
                    {validation && repassword ? (
                      <label className="error-message">{validation}</label>
                    ) : null}
                  </div>
                  <div className="signin-area mb-3.5">
                    {disabledBtn ? (
                      <button
                        type="submit"
                        className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-slate-400 font-bold-400 flex justify-center bg-slate-100 items-center disabled:cursor-not-allowed shadow-none"
                        disabled
                      >
                        Continue
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-white font-bold flex justify-center bg-purple items-center"
                        onClick={updatePassword}
                      >
                        Continue
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ThankYou
              className={`thankyou-section ${message ? "active" : ""}`}
            />
          )}
        </div>
      </AuthLayout>
    </>
  );
}
