import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { toast } from "react-toastify";
import { useAccount, useSwitchNetwork } from "wagmi";
import logo from "../../assets/images/SEGMINT-Logo_Blue.png";
import Web3 from "web3";
import ApiService from "../../Service/ApiService";
import ModalCom from "../Helpers/ModalCom";
import NoData from "../NoData/NoData";
import { getLockProposal } from "../../Service/graphService";
var timer;
export default function LockingProposal() {
  const { switchNetwork } = useSwitchNetwork();

  const { multiSigAddress, proposalId, timeStamp, chainId } = useParams();
  const [proposalData, setProposalData] = useState([]);

  const account = useAccount();
  const [transactionHash, setTransactionHash] = useState(null);
  const [transactionLoader, setTransactionLoader] = useState(false);
  const [buying, setBuying] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(0);
  const [deployTransactionCheck, setDeployTransactionCheck] = useState(false);
  const [lockedNFTData, setLockedNFT] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);

  const getProposalData = async () => {
    const walletChainId = await account?.connector?.getChainId();
    if (account?.address) {
      const data = await getLockProposal(multiSigAddress, proposalId, chainId);
      if (data.length > 0) {
        let proposal = data[0];
        let path =
          proposal.proposalType == "LOCK"
            ? `SegMint_MultiSig/${walletChainId}/v1/getLockProposalNFTs`
            : `SegMint_MultiSig/${walletChainId}/v1/getUnlockProposalNFTs`;

        let body = {
          blockchain_env: "testnet",
          lockOrUnlockProposalID_: proposal.proposalId,
          SegMintVaultAddress: proposal.vaultContractAddress,
          SegMintMultiSigAddress: proposal.contractAddress,
        };
        console.log(body, "{{{BODY}}}");

        ApiService.ledgerPost(path, body)
          .then((res) => {
            console.log(res.data.LockedNFTs, "LOCKED NFTS");
            setLockedNFT(res.data.LockedNFTs);
          })
          .catch((err) => console.log(err));
      }
      setProposalData(data);
    } else {
      setProposalData([]);
      toast.error("pls connect your wallet");
    }
  };

  useEffect(() => {
    if (timeStamp) {
      const conversion = new Date(timeStamp * 1000); // Convert timestamp to milliseconds
      const date = conversion.toLocaleDateString(); // Convert date to string format
      const currentDate = new Date(); // Get the current date
      const currentDateStr = currentDate.toLocaleDateString();
      console.log("Expiration date is in the past", currentDateStr, date);
      if (date >= currentDateStr) {
        setDisableBtn(false);
      } else if (date < currentDateStr) {
        setDisableBtn(true);
      }
    }
  }, [timeStamp]);
  useEffect(() => {
    getProposalData();
  }, [multiSigAddress, proposalId, account?.address]);

  const proposalVotesHandler = async (type1) => {
    const walletChainId = await account?.connector?.getChainId();

    if (chainId === walletChainId) {
      await switchNetwork?.(chainId);
    }
    if (chainId == walletChainId) {
      setBuying(true);
      setTransactionLoader(true);
      setTransactionStatus(0);
      const provider = await account.connector.getProvider();
      const x = new Web3(provider.provider);
      let path;
      const proposal = proposalData[0];
      let body = {
        lockorUnlockProposalID_: proposal?.proposalId,
        SegMintMultiSigAddress: proposal?.contractAddress,
        walletAddress: account?.address,
        blockchain_env: "Testnet",
      };
      if (type1 === "accept") {
        path = `SegMint_MultiSig/${walletChainId}/v1/approveLockorUnlockProposal`;
      }
      if (type1 === "revoke") {
        path = `SegMint_MultiSig/${walletChainId}/v1/revokeLockorUnlockProposal`;
      }
      ApiService.ledgerPost(path, body)
        .then((res) => {
          x.eth.sendTransaction(
            res.data.raw_data,
            async function (err, result) {
              if (err) {
                console.log(err);
                toast.error("Internal server error. Please try later!");
                setTransactionLoader(false);
                setBuying(false);
              }
              if (result) {
                const receipt = await getTransactionReceipt(x, result);
                if (receipt) {
                  setTransactionLoader(false);
                  setTransactionHash(result);
                  const data = await getLockProposal(
                    multiSigAddress,
                    proposalId
                  );
                  setProposalData([
                    {
                      ...data[0],
                      signers: [
                        {
                          wallet: account?.address,
                          approved: true,
                        },
                        ...data[0].signers.filter(
                          (i) =>
                            i?.wallet?.toLowerCase() !=
                            account?.address?.toLowerCase()
                        ),
                      ],
                    },
                  ]);
                }
              }
            }
          );
        })
        .catch((err) => console.log(err));
    }
  };

  console.log(proposalData, "{{PROPOSALDATA SET}}");
  const getTransactionReceipt = async (web3, txHash) => {
    return new Promise((resolve, reject) => {
      const checkInterval = setInterval(async () => {
        try {
          const receipt = await web3.eth.getTransactionReceipt(txHash);
          if (receipt) {
            clearInterval(checkInterval);
            resolve(receipt);
          }
        } catch (err) {
          reject(err);
        }
      }, 1000); // check every second
    });
  };

  async function isPending() {
    const provider = await account.connector.getProvider();
    const x = new Web3(provider.provider);
    return (await x.eth.getTransactionReceipt(transactionHash)) == null;
  }

  const checkTransactionHashDeploy = async () => {
    const pending = await isPending();
    const provider = await account.connector.getProvider();
    const x = new Web3(provider.provider);
    if (!pending) {
      setDeployTransactionCheck(true);
      const result = await x.eth.getTransactionReceipt(transactionHash);
      if (result.status) {
        setTransactionStatus(1);
      } else {
        setTransactionStatus(2);
      }
    }
  };
  const initializeTransactionStatus = () => {
    setDeployTransactionCheck(false);
    setBuying(false);
    setTransactionStatus(0);
  };
  useEffect(() => {
    if (transactionHash !== null) {
      timer = setInterval(() => {
        checkTransactionHashDeploy();
      }, 1000);
    }
  }, [transactionHash]);

  useEffect(() => {
    if (transactionHash !== null && deployTransactionCheck) {
      clearInterval(timer);
    }
  }, [deployTransactionCheck]);

  const COLUMNS = [
    {
      Header: "",
      accessor: "checkbox",
    },
    {
      Header: "List",
      accessor: "list",
      Cell: ({ row }) => {
        return row?.index + 1 || <span>NA</span>;
      },
    },
    {
      Header: "Proposal Type",
      accessor: "proposalType",
      Cell: ({ row }) => {
        return row?.original?.proposalType || <span>NA</span>;
      },
    },
    {
      Header: "Approved Votes",
      accessor: "approvedVotesBySigner",
      Cell: ({ row }) => {
        return row?.original?.approvedVotesBySigner || <span>NA</span>;
      },
    },
    {
      Header: "Revoked Votes",
      accessor: "revokedVotesBySigner",
      Cell: ({ row }) => {
        return row?.original?.revokedVotesBySigner || <span>NA</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        return row?.original?.status || <span>NA</span>;
      },
    },
    {
      Header: "Action",
      accessor: "",
      Cell: ({ row }) => {
        let isProposer =
          row?.original?.status == "Executed"
            ? false
            : row?.original?.status == "Revoked"
            ? false
            : account?.address?.toLowerCase() ==
              row?.original?.proposer?.toLowerCase();
        let approved = row?.original?.signers?.find(
          (i) => i?.wallet?.toLowerCase() == account?.address?.toLowerCase()
        )?.approved;
        return (
          <>
            <div className="flex flex-row gap-2">
              {isProposer && (
                <button
                  type="button"
                  onClick={() => proposalVotesHandler("revoke")}
                  className="w-[100px] flex h-12 justify-center items-center btn-gradient text-center text-base rounded-full text-white"
                >
                  Revoke
                </button>
              )}
            </div>
            <div className="flex flex-row gap-2">
              {!approved && disableBtn === false && (
                <button
                  type="button"
                  onClick={() => proposalVotesHandler("accept")}
                  className="w-[100px] flex h-12 justify-center items-center btn-gradient text-center text-base rounded-full text-white"
                >
                  Accept
                </button>
              )}
            </div>
          </>
        );
      },
    },
  ];
  console.log(proposalData, "PROPOSAL DATA HERE");
  const columns = useMemo(() => COLUMNS, [proposalData, account?.address]);
  const data = useMemo(() => proposalData, [proposalData, account?.address]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy
    );

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={transactionLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container px-4 bg-white m-auto">
        <div className="grid grid-cols-12 bg-white gap-4">
          <div className="col-span-4">
            {/* <h1 className="text-xl font-bold text-dark-gray tracking-wide">
              {proposalData[0]?.proposalType !== "UNLOCK"
                ? "Lock Proposal"
                : "Unlock Proposal"}
            </h1> */}
            <img src={logo} alt="nft" height="66px" width="166px" />
          </div>
          <div className="col-span-4"></div>
          <div className="col-span-4"></div>
        </div>
        <div className="flex mt-2 flex-col">
          <div className="overflow-x-auto m-auto">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="w-full overflow-x-auto border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table
                  className="min-w-full divide-y divide-gray-200 dark:divide-gray-700"
                  {...getTableProps()}
                >
                  <thead className="bg-gray-50 dark:bg-gray-800">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        className="px-6 py-4"
                        key={Math.random().toString(36).substr(2, 9)}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                            key={Math.random().toString(36).substr(2, 9)}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ""
                                  : ""
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900"
                    {...getTableBodyProps()}
                  >
                    {proposalData.length === 0 ? (
                      <tr>
                        <td colSpan={12}>
                          <NoData info="Ooops, we could not find any user" />
                        </td>
                      </tr>
                    ) : (
                      rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap"
                            key={Math.random().toString(36).substr(2, 9)}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap"
                                  key={Math.random().toString(36).substr(2, 9)}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-[30px]">
                {lockedNFTData?.length == 0 ? (
                  <></>
                ) : (
                  <>
                    {console.log(lockedNFTData)}
                    <div className="col-span-4 mt-[20px]">
                      <h1 className="text-xl font-bold text-dark-gray tracking-wide">
                        NFTs
                      </h1>
                    </div>
                    {lockedNFTData.map((item, index) => (
                      <div key={index}>
                        <div className="card-style-two w-full h-[322px] p-[20px] bg-white rounded-2xl section-shadow cursor-pointer relative ">
                          <div className="flex flex-col justify-between w-full h-full">
                            <div className="thumbnail-area w-full overflow-hidden">
                              <div className="w-full h-[236px] rounded-xl overflow-hidden relative">
                                <div>
                                  <div className="">
                                    <img
                                      src={item.tokenURI}
                                      alt="banner"
                                      className="w-full h-full object-cover absolute "
                                      style={{ filter: "blur(48px)" }}
                                    />
                                    <img
                                      src={item.tokenURI}
                                      alt="banner"
                                      className="w-full h-full object-scale-down absolute  transition-transform duration-300 transform hover:scale-105"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="details-area">
                              <div className="flex items-center gap-1">
                                <h1 className="font-bold text-xl tracking-wide line-clamp-1 text-dark-gray capitalize truncate overflow-hidden">
                                  {item?.tokenName}
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {buying && (
        <ModalCom>
          <div className="lg:w-[600px] w-full bg-white lg:rounded-2xl">
            <div className="w-full h-[92px] lg:px-10 lg:py-4 px-[30px] py-[23px] border-b border-light-purple flex justify-between items-center">
              {transactionStatus === 1 || transactionStatus === 4 ? (
                <h1 className="text-2xl tracking-wide font-semibold text-dark-gray">
                  Success
                </h1>
              ) : null}
              {transactionStatus === 2 || transactionStatus === 5 ? (
                <h1 className="text-2xl tracking-wide font-semibold text-dark-gray">
                  Failure
                </h1>
              ) : null}
              {transactionStatus === 0 || transactionStatus === 3 ? (
                <h1 className="text-2xl tracking-wide font-semibold text-dark-gray">
                  Please wait
                </h1>
              ) : null}
            </div>
            <div className="">
              {transactionStatus === 1 && (
                <div className="w-full flex flex-col justify-center py-8">
                  <p className="lg:text-md text-md font-md text-center mb-7 flex flex-col justify-center">
                    Success! Your transaction has been processed successfully
                    with{" "}
                    <a
                      className="opensea-contract-link lg:text-md text-md break-words"
                      style={{
                        textDecoration: "none",
                      }}
                      target="_blank"
                      rel="noreferrer"
                      href={
                        "https://testnet.snowtrace.io/tx/" + transactionHash
                      }
                    >
                      {transactionHash}
                    </a>
                  </p>
                  <div className="flex space-x-2.5 justify-center">
                    <button
                      onClick={() => setBuying(false)}
                      type="button"
                      className="text-white primary-gradient text-lg tracking-wide w-[112px] h-[44px] mx-12 rounded-full"
                    >
                      ok
                    </button>
                  </div>
                </div>
              )}
              {transactionStatus === 2 && (
                <div className="w-full flex flex-col justify-center py-10">
                  <p className="lg:text-lg text-md font-md text-center mb-7">
                    Transaction is failed, please retry later.
                  </p>
                  <div className="flex space-x-2.5 justify-center">
                    <button
                      onClick={() => initializeTransactionStatus()}
                      type="button"
                      className="text-white primary-gradient text-lg tracking-wide w-[112px] h-[44px] mx-12 rounded-full"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
              {transactionStatus === 0 && (
                <div className="w-full flex flex-col justify-center py-16">
                  <p className="lg:text-lg text-md font-md text-center mb-7">
                    Please confirm the blockchain transaction and wait for a
                    moment.
                  </p>
                  <div className="flex justify-center items-center">
                    <FadeLoader color="#5356fb" size={300} />
                  </div>
                </div>
              )}
              {transactionStatus === 3 && (
                <div className="w-full flex flex-col justify-center py-16">
                  <p className="lg:text-lg text-md font-md text-center mb-7">
                    Please confirm the API transaction and wait for a moment.
                  </p>
                  <div className="flex justify-center items-center">
                    <FadeLoader color="#5356fb" size={300} />
                  </div>
                </div>
              )}
              {transactionStatus === 4 && (
                <div className="w-full flex flex-col justify-center mb-7 py-10">
                  <p className="lg:text-lg text-md font-md text-center mb-7">
                    Success.
                  </p>
                  <div className="flex space-x-2.5 justify-center">
                    <button
                      onClick={() => initializeTransactionStatus()}
                      type="button"
                      className="text-white primary-gradient text-lg tracking-wide w-[112px] h-[44px] mx-12 rounded-full w-full"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
              {transactionStatus === 5 && (
                <div className="w-full flex flex-col justify-center mb-7 py-10">
                  <p className="lg:text-xl text-md font-md text-center mb-7">
                    Failure.
                  </p>
                  <div className="flex space-x-2.5 justify-center">
                    <button
                      onClick={() => initializeTransactionStatus()}
                      type="button"
                      className="text-white primary-gradient text-lg tracking-wide w-[112px] h-[44px] mx-12 rounded-full w-full"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ModalCom>
      )}
    </>
  );
}
