import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
//import { forgetPass } from "../../../apis/apis";
//import { forgetPasswordFn } from "../../../apis/authApis/authApis";
import titleShape from "../../../assets/images/shape/title-shape-two.svg";
import InputCom from "../../Helpers/Inputs/InputCom";
import AuthLayout from "../AuthLayout";
import ApiService from "../../../Service/ApiService";
import { sendForgotOTP } from "../../../apis/apis";
import { errorMsgHandler } from "../../../responseMsg/messages";
export default function ForgotPassword() {
  const navigate = useNavigate();
  const [disabledBtn, setDisabledBtn] = React.useState(true);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!email || loading) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }
  }, [email, loading]);

  const forgetPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    sessionStorage.setItem("email", email);
    const body = {
      email: email,
    };
    ApiService.post(sendForgotOTP, body)
      .then((res) => {
        console.log("res", res);
        navigate("/reset-password");
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.data.errors.msg[0].msg) {
          const msg = errorMsgHandler(error.response.data.errors.msg[0].msg);
          toast.error(msg);
        } else {
          const msg = errorMsgHandler(error.response.data.errors.msg);
          toast.error(msg);
        }
        setLoading(false);
      });
  };

  return (
    <>
      <AuthLayout
        slogan="FracX"
      >
        <form onSubmit={forgetPassword} className="h-full w-full flex-1">
          <div className="content-wrapper xl:bg-white xl:px-[70px] w-full sm:w-full   2xl:px-[100px] h-full rounded-xl ">
            <div className="flex flex-col justify-center w-full h-full px-[7%]">
              <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                <h1 className="sm:text-5xl text-4xl font-bold sm:leading-[74px] text-dark-gray">
                  Reset your password
                </h1>
                <div className="shape sm:w-[377px] w-[270px] -mt-1 ml-5">
                  <img
                    src={titleShape}
                    alt="shape"
                    style={{ filter: "hue-rotate(300deg)" }}
                  />
                </div>
              </div>
              <div className="input-area">
                <div className="input-item mb-5">
                  <InputCom
                    value={email}
                    placeholder="example@domain.com"
                    label="Email Address"
                    name="email"
                    type="email"
                    iconName="message"
                    inputHandler={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="signin-area mb-3.5">
                  {disabledBtn ? (
                    <button
                      type="button"
                      className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-slate-400 font-bold-400 flex justify-center bg-slate-100 items-center disabled:cursor-not-allowed shadow-none"
                      disabled
                    >
                      {loading ? "Loading..." : "Send Code"}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-white font-bold flex justify-center bg-button-backgroud-color items-center"
                    >
                      {" "}
                      Send Code
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </AuthLayout>
    </>
  );
}
