import React, { useEffect, useState } from "react";

import Proposals from "./Proposals";
import AuthLayout from "../AuthPages/AuthLayout";
import { useAccount, useSwitchNetwork } from "wagmi";
import {
  // DynamicConnectButton,
  DynamicUserProfile,
  DynamicWidget,
} from "@dynamic-labs/sdk-react";
import { useParams } from "react-router-dom";
import ApiService from "../../Service/ApiService";
import titleShape from "../../assets/images/shape/title-shape.svg";
import { toast } from "react-toastify";
function GuestPage() {
  let { multiSigAddress, chainId } = useParams();
  const { switchNetwork } = useSwitchNetwork();
  const account = useAccount();
  const [providerChainId, setProviderChainId] = useState("");
  const [guestAuthToken, setGuestAuthToken] = useState(null);
  //   const switchToNetwork = () => {
  //
  //   };
  useEffect(() => {
    console.log(providerChainId, chainId);
  }, [providerChainId, chainId]);
  const getChainId = async () => {
    const chainId = await account?.connector?.getChainId();
    setProviderChainId(chainId);
    console.log(chainId);
  };
  useEffect(() => {
    getChainId();
  }, [account.address]);

  const verifyGuestUser = async () => {
    const walletAddress = await account?.address;
    ApiService.post("/segMintSafe/guestToken", {
      walletAddress: walletAddress,
      contractAddress: multiSigAddress,
    })
      .then((res) => {
        console.log(res, "{{guestAuthToken++++}}");

        setGuestAuthToken(res?.data?.token);
        toast.success("Wallet Authorized successfully");
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  useEffect(() => {
    verifyGuestUser();
  }, [account.address, multiSigAddress]);

  useEffect(() => {
    if (Number(providerChainId) !== Number(chainId)) {
      swicthNetworkCase();
    }
  }, [chainId, providerChainId]);

  useEffect(() => {
    console.log(guestAuthToken, "{{guestAuthToken}}");
  }, [guestAuthToken]);
  const swicthNetworkCase = async () => {
    await switchNetwork?.(chainId);
    return;
  };

  return (
    <>
      <DynamicUserProfile />
      {guestAuthToken !== null ? (
        Number(providerChainId) === Number(chainId) ? (
          <Proposals />
        ) : (
          <AuthLayout
            slogan="FracX
"
          >
            <div className="content-wrapper flex justify-center items-center xl:bg-white 2xl:w-full xl:w-full 2xl:h-full xl:h-full rounded-xl 2xl:px-[164px] xl:px-[56px] sm:px-7 px-5 ">
              <div className="w-full">
                <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                  <h1 className="text-5xl font-bold leading-[74px] text-dark-gray">
                    Switch The Network
                  </h1>
                  <div className="shape -mt-5">
                    <img
                      src={titleShape}
                      alt="shape"
                      style={{ filter: "hue-rotate(300deg)" }}
                    />
                  </div>
                </div>
                <div
                  className=" w-full  text-xl  font-bold flex justify-center  items-center"
                  data-testid="exampleChild"
                >
                  <DynamicWidget />
                </div>
              </div>
            </div>
          </AuthLayout>
        )
      ) : (
        <AuthLayout
          slogan="FracX
"
        >
          <div className="content-wrapper flex justify-center items-center xl:bg-white 2xl:w-full xl:w-full 2xl:h-full xl:h-full rounded-xl 2xl:px-[164px] xl:px-[56px] sm:px-7 px-5 ">
            <div className="w-full">
              <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                <h1 className="text-5xl font-bold leading-[74px] text-dark-gray">
                  MultiSig Proposals
                </h1>
                <div className="shape -mt-5">
                  <img
                    src={titleShape}
                    alt="shape"
                    style={{ filter: "hue-rotate(300deg)" }}
                  />
                </div>
              </div>
              <div
                className=" w-full  text-xl  font-bold flex justify-center  items-center"
                data-testid="exampleChild"
              >
                <DynamicWidget />
              </div>
            </div>
          </div>
        </AuthLayout>
      )}
    </>
  );
}

export default GuestPage;
