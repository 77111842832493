import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
// import FourZeroFour from "./components/FourZeroFour";
const FourZeroFour = lazy(() => import("./components/FourZeroFour"));

//import ScrollToTop from "./components/Helpers/ScrollToTop";
const ScrollToTop = lazy(() => import("./components/Helpers/ScrollToTop"));

//import MyCollection from "./components/MyCollection";
const MyCollection = lazy(() => import("./components/MyCollection"));

//import Notification from "./components/Notification";
const Notification = lazy(() => import("./components/Notification"));

//import AuthRoute from "./middleware/AuthRoute";
const AuthRoute = lazy(() => import("./middleware/AuthRoute"));

//import AcitveBidsPage from "./views/AcitveBidsPage";
const AcitveBidsPage = lazy(() => import("./views/AcitveBidsPage"));

//import AuthProfilePage from "./views/AuthProfilePage";
const AuthProfilePage = lazy(() => import("./views/AuthProfilePage"));

//import CollectionItemPage from "./views/CollectionItemPage";
const CollectionItemPage = lazy(() => import("./views/CollectionItemPage"));

import ForgotPasswordPages from "./views/ForgotPasswordPages";
// const ForgotPasswordPages = lazy(() => import("./views/ForgotPasswordPages"));

//import HistoryPage from "./views/HistoryPage";
const HistoryPage = lazy(() => import("./views/HistoryPage"));

//import HomePages from "./views/HomePages";
const HomePages = lazy(() => import("./views/HomePages"));

import LoginPage from "./views/LoginPage";
// const LoginPage = lazy(() => import("./views/LoginPage"));

//import MarketPlacePage from "./views/MarketPlacePage";
const MarketPlacePage = lazy(() => import("./views/MarketPlacePage"));

//import MyWalletPage from "./views/MyWalletPage";
const MyWalletPage = lazy(() => import("./views/MyWalletPage"));

//import SavedPage from "./views/SavedPage";
const SavedPage = lazy(() => import("./views/SavedPage"));
const AdminPage = lazy(() => import("./views/AdminPage"));

//import SegmintNftPage from "./views/SegmintNftPage";
const SegmintNftPage = lazy(() => import("./views/SegmintNftPage"));

//import SellPage from "./views/SellPage";
const SellPage = lazy(() => import("./views/SellPage"));

//import SettingsPage from "./views/SettingsPage";
const SettingsPage = lazy(() => import("./views/SettingsPage"));

//import ShopDetailsPage from "./views/ShopDetailsPage";
const ShopDetailsPage = lazy(() => import("./views/ShopDetailsPage"));

import SignupPage from "./views/SignupPage";
// const SignupPage = lazy(() => import("./views/SignupPage"));

import UpdatePasswordPages from "./views/UpdatePasswordPages";
// const UpdatePasswordPages = lazy(() => import("./views/UpdatePasswordPages"));

//import UploadProductPage from "./views/UploadProductPage";
const UploadProductPage = lazy(() => import("./views/UploadProductPage"));

//import UserProfilePage from "./views/UserProfilePage";
const UserProfilePage = lazy(() => import("./views/UserProfilePage"));

import VerifyYouPages from "./views/VerifyYouPages";
// const VerifyYouPages = lazy(() => import("./views/VerifyYouPages"));

import VerifyEmailPage from "./views/VerifyEmail";
// const VerifyEmailPage = lazy(() => import("./views/VerifyEmail"));

import ResetPassword from "./components/AuthPages/ResetPassword";
import GuestPage from "./components/GuestPages";
import LockingProposal from "./components/GuestPages/LockProposal";
// const ResetPassword = lazy(() =>
//   import("./components/AuthPages/ResetPassword")
// );

//import MyNFTPage from "./views/MyNFTPage";
const MyNFTPage = lazy(() => import("./views/MyNFTPage"));

//import Index from "./views/MyNFTPage";
const Verifykyc = lazy(() => import("./components/Kyc/index"));

//import SelectSegmintNFTPage from "./views/SelectSegmintNFTPage";
const SelectSegmintNFTPage = lazy(() => import("./views/SelectSegmintNFTPage"));

//import SelectStakeNFTPage from "./views/SelectStakeNFTPage";
const SelectStakeNFTPage = lazy(() => import("./views/SelectStakeNFTPage"));

const MultiSigPage = lazy(() => import("./components/MultiSig/index"));
const MultiSigProposals = lazy(() =>
  import("./components/MultiSig/MultiSigProposals")
);

const LockProposal = lazy(() => import("./components/MultiSig/LockProposal"));
const CreateSafeComponent = lazy(() =>
  import("./components/MultiSig/CreateSafe")
);
const SegmintVault = lazy(() => import("./components/SegmintVault/index"));
const ImportNFTList = lazy(() =>
  import("./components/SegmintVault/ImportNFTSelection")
);

const UnlockNFTTable = lazy(() =>
  import("./components/SegmintVault/UnlockNFTTable")
);
const LockedContractNFT = lazy(() =>
  import("./components/SegmintVault/LockedContractNFT")
);
const LockingProcess = lazy(() =>
  import("./components/SegmintVault/NFTLockingProcedure")
);

export default function Routers() {
  return (
    <ScrollToTop>
      <Suspense fallback={<p>Loading...</p>}>
        <Routes>
          {/* guest routes */}
          <Route exact path="/login" element={<LoginPage />} />
          <Route
            exact
            path="/guest/multisig-proposal/:multiSigAddress/:chainId"
            element={<GuestPage />}
          />
          <Route
            exact
            path="/guest/lock-proposal/:multiSigAddress/:proposalId/:timeStamp/:chainId"
            element={<LockingProposal />}
          />
          {/* <Route exact path="/signup" element={<SignupPage />} /> */}
          <Route
            exact
            path="/signup/:id/:emailparam"
            element={<SignupPage />}
          />
          <Route
            exact
            path="/forgot-password"
            element={<ForgotPasswordPages />}
          />
          <Route
            exact
            path="/update-password"
            element={<UpdatePasswordPages />}
          />
          <Route exact path="/verify-you" element={<VerifyYouPages />} />
          <Route exact path="/verify-email" element={<VerifyEmailPage />} />
          <Route
            exact
            path="/reset-password/:email?"
            element={<ResetPassword />}
          />

          {/* private route */}
          <Route element={<AuthRoute />}>
            <Route exact path="/" element={<HomePages />} />
            <Route exact path="/market-place" element={<MarketPlacePage />} />
            <Route
              exact
              path="/active-bids/:id/:creatorWallet/"
              element={<AcitveBidsPage />}
            />
            <Route exact path="/active-bids/:id" element={<AcitveBidsPage />} />
            <Route exact path="/notification" element={<Notification />} />
            <Route exact path="/shop-details" element={<ShopDetailsPage />} />
            <Route exact path="/my-wallet" element={<MyWalletPage />} />
            <Route exact path="/my-collection" element={<MyCollection />} />
            <Route
              exact
              path="/my-collection/collection-item"
              element={<CollectionItemPage />}
            />
            {/* <Route exact path="/segmint-nft" element={<UploadProductPage />} /> */}
            <Route exact path="/sell" element={<SellPage />} />
            <Route exact path="/saved" element={<SavedPage />} />
            <Route exact path="/admin" element={<AdminPage />} />

            <Route exact path="/history" element={<HistoryPage />} />
            <Route
              exact
              path="/upload-product"
              element={<UploadProductPage />}
            />
            <Route exact path="/segmint-nft" element={<SegmintNftPage />} />
            <Route exact path="/profile" element={<AuthProfilePage />} />
            <Route path="/profile/:id" element={<AuthProfilePage />} />
            <Route exact path="/user-profile" element={<UserProfilePage />} />
            <Route exact path="/settings" element={<SettingsPage />} />
            <Route exact path="/my-nft" element={<MyNFTPage />} />
            <Route exact path="/multi-sig" element={<MultiSigPage />} />
            <Route
              exact
              path="/create-safe"
              element={<CreateSafeComponent />}
            />
            <Route exact path="/fracx-vault" element={<SegmintVault />} />
            <Route exact path="/unlock-nft" element={<UnlockNFTTable />} />
            <Route
              exact
              path="/locked-contract-nft"
              element={<LockedContractNFT />}
            />
            <Route exact path="/select-nft" element={<ImportNFTList />} />
            <Route
              exact
              path="/multisig-proposal/:multiSigAddress/:chainId"
              element={<MultiSigProposals />}
            />
            <Route
              exact
              path="/lock-proposal/:multiSigAddress/:proposalId/:timeStamp/:chainId"
              element={<LockProposal />}
            />
            <Route exact path="/lock-my-nft" element={<LockingProcess />} />

            <Route
              exact
              path="/fracx-my-nft"
              element={<SelectSegmintNFTPage />}
            />
            <Route
              exact
              path="/stake-my-nft"
              element={<SelectStakeNFTPage />}
            />
            <Route exact path="/verify-kyc" element={<Verifykyc />} />
          </Route>
          <Route path="*" element={<FourZeroFour />} />
        </Routes>
      </Suspense>
    </ScrollToTop>
  );
}
