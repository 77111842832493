import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import titleShape from "../../../assets/images/shape/text-shape-three.svg";
import AuthLayout from "../AuthLayout";
import Otp from "./Otp";
import ApiService from "../../../Service/ApiService";
import { verifyRegisterOTP, resendRegisterOTP } from "../../../apis/apis";
import { toast } from "react-toastify";
import { checkVerified } from "../../../Service/checkVerified";
import AuthService from "../../../Service/AuthService";
export default function VerifyEmail() {
  const navigate = useNavigate();
  const [isOtpFilled, setIsOtpFilled] = useState(false);
  const [otpFilled, setOtp] = useState(null);
  const [isVerified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const verifyCheck = async () => {
    const verified = await checkVerified();
    if (verified == true) {
      window.open("/", "_self");
    }
    setVerified(verified);
  };
  useEffect(() => {
    verifyCheck();
  }, [isVerified]);
  const getUserEmail = () => {
    const user = localStorage.getItem("user");
    if (user) {
      // setLoggedIn(true);
      return JSON.parse(user).email;
    }
    return sessionStorage.getItem("email");
  };
  const onContinue = async (e) => {
    e.preventDefault();
    setLoading(true);
    const email = getUserEmail();
    const body = { email: email, otp: otpFilled };
    //console.log(otpFilled, body);
    await ApiService.post(verifyRegisterOTP, body)
      .then((res) => {
        if (res.data.success == false) {
          toast.error("Incorrect OTP");
          setLoading(false);
        } else {
          toast.success("Email Verified Successfully");
          setTimeout(() => {
            if (AuthService.get().isAuthenticated()) {
              setLoading(false);
              navigate("/settings");
            } else {
              setLoading(false);
              navigate("/login");
            }
          }, [3000]);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  const resendOTP = async (e) => {
    e.preventDefault();
    const email = getUserEmail();
    const body = { email: email };
    await ApiService.post(resendRegisterOTP, body)
      .then((res) => {
        if (res.data.success == false) {
          toast.error("Invalid Request");
        } else {
          toast.success("OTP send Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };
  return (
    <>
      <AuthLayout
        slogan="FracX"
      >
        <form onSubmit={onContinue} className="h-full w-full flex-1">
          <div className="content-wrapper xl:bg-white w-full sm:w-auto px-5 xl:px-[70px] 2xl:px-[100px] h-full rounded-xl flex flex-col justify-center">
            <div className="title-area flex flex-col justify-center items-center relative text-center mb-8">
              <h1 className="sm:text-5xl text-4xl font-bold leading-[74px] text-dark-gray">
                Verification Code
              </h1>
              <div className="shape sm:w-[377px] w-[270px] -mt-5 ml-5">
                <img src={titleShape} alt="shape" />
              </div>
            </div>
            <div className="input-area">
              <Otp setIsOtpFilled={setIsOtpFilled} setOtp={setOtp} />
              <div className="signin-area mb-3.5">
                {!isOtpFilled || loading ? (
                  <button
                    type="submit"
                    className="disabled:cursor-not-allowed w-full rounded-[50px] h-[58px] mb-6 text-xl text-slate-400 font-bold flex justify-center bg-slate-100 items-center"
                    disabled
                  >
                    Continue
                  </button>
                ) : (
                  <button
                    disabled={false}
                    type="submit"
                    className="w-full rounded-[50px] h-[58px] mb-6 text-xl text-white font-bold flex justify-center bg-purple items-center"
                  >
                    Continue
                  </button>
                )}
              </div>
              <div className="resend-code flex justify-center">
                <p className="text-lg text-thin-light-gray font-normal">
                  Didn’t recieved?
                  <button
                    type="button"
                    className="ml-2 text-purple font-medium"
                    onClick={resendOTP}
                  >
                    Please resend
                  </button>
                </p>
              </div>
              {AuthService.get().isAuthenticated() ? null : (
                <div className="resend-code flex justify-center">
                  <p className="text-lg text-thin-light-gray font-normal">
                    <button
                      type="button"
                      onClick={() => navigate("/login")}
                      className="ml-2 text-purple font-medium"
                    >
                      Click here to Login
                    </button>
                  </p>
                </div>
              )}
            </div>
          </div>
        </form>
      </AuthLayout>
    </>
  );
}
