import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login } from "../../../apis/apis";
// import { userLogin } from "../../../apis/authApis/authApis";
import { userLogin, singInGoogle } from "../../../apis/authApis/authApis";
import googleLogo from "../../../assets/images/google-logo.svg";
import titleShape from "../../../assets/images/shape/title-shape.svg";
import InputCom from "../../Helpers/Inputs/InputCom";
import AuthLayout from "../AuthLayout";
import useAnalyticsEventTracker from "../../../Service/GoogleAnalyticsService";
import LoginWithDynamicxyz from "./LoginWithDynamicxyz";

export default function Login() {
  const navigate = useNavigate();
  const [checked, setValue] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [disabledBtn, setDisabledBtn] = React.useState(true);
  const [showPass, setShowPass] = React.useState(false);

  const rememberMe = () => {
    setValue(!checked);
  };

  // email
  const [email, setMail] = useState("");
  const handleEmail = (e) => {
    setMail(e.target.value);
  };
  // password
  const [password, setPassword] = useState("");
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const gaEventTracker = useAnalyticsEventTracker(
    "Login",
    "Landed on login page",
    "Landed",
    "login"
  );
  const gaClickEvent = useAnalyticsEventTracker(
    "Login",
    "Clicked on login button",
    "Clicked",
    "login"
  );
  useEffect(() => {
    gaEventTracker();
  }, []);
  useEffect(() => {
    if (!email || !password) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }
  }, [email, password]);

  const doLogin = async (e) => {
    e.preventDefault();
    if (email !== "" && password !== "") {
      const body = {
        email: email,
        password: password,
      };
      setLoginLoading(true);
      const isUserValidated = await userLogin(login, body);
      if (isUserValidated.status) {
        setTimeout(() => {
          toast.success(isUserValidated.message);
          navigate("/");
          setLoginLoading(false);
        }, 500);
      } else {
        toast.error(`${isUserValidated.message}`);
        setLoginLoading(false);
      }
    }
  };
  const googleLogin = async (e) => {
    e.preventDefault();

    setLoginLoading(true);
    const isUserValidated = await singInGoogle();
    if (isUserValidated.status) {
      setTimeout(() => {
        toast.success(isUserValidated.message);
        navigate("/");
        setLoginLoading(false);
      }, 500);
    } else {
      toast.error(`${isUserValidated.message}`);
      setLoginLoading(false);
    }
  };

  return (
    <>
      <AuthLayout
        slogan="FracX"
      >
        <form className="h-full flex-1" onSubmit={doLogin}>
          <div className="content-wrapper flex justify-center items-center xl:bg-white 2xl:w-full xl:w-full 2xl:h-full xl:h-full rounded-xl 2xl:px-[164px] xl:px-[56px] sm:px-7 px-5 ">
            <div className="w-full">
              <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                <h1 className="text-5xl font-bold leading-[74px] text-dark-gray">
                  Log In
                </h1>
                <div className="shape -mt-5">
                  <img
                    src={titleShape}
                    alt="shape"
                    style={{ filter: "hue-rotate(300deg)" }}
                  />
                </div>
              </div>
              <div className="input-area">
                <div className="input-item mb-5">
                  <InputCom
                    value={email}
                    inputHandler={handleEmail}
                    placeholder="example@domain.com"
                    label="Email Address"
                    name="email"
                    type="email"
                    iconName="message"
                  />
                </div>
                <div className="input-item mb-5">
                  <InputCom
                    value={password}
                    inputHandler={handlePassword}
                    placeholder="●●●●●●●●●●"
                    label="Password"
                    name="password"
                    type={!showPass ? "password" : "text"}
                    iconName="password"
                    setShowPass={setShowPass}
                    showPass={showPass}
                  />
                </div>
                <div className="forgot-password-area flex justify-between items-center mb-7">
                  <div className="remember-checkbox flex items-center space-x-2.5">
                    <button
                      onClick={rememberMe}
                      type="button"
                      className="w-5 h-5 text-dark-gray flex justify-center items-center border border-light-gray"
                    >
                      {checked && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                    </button>
                    <span
                      onClick={rememberMe}
                      className="text-base text-dark-gray"
                    >
                      Remember Me
                    </span>
                  </div>
                  <button
                    type="button"
                    className="text-base text-button-backgroud-color"
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot Password?
                  </button>
                </div>
                <div className="signin-area mb-3.5">
                  <div className="flex justify-center">
                    {disabledBtn ? (
                      <button
                        type="button"
                        className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-slate-400 font-bold-400 flex justify-center bg-slate-100 items-center disabled:cursor-not-allowed shadow-none"
                        disabled={true}
                      >
                        Sign In
                      </button>
                    ) : (
                      <button
                        // onClick={doLogin}
                        disabled={loginLoading}
                        type="submit"
                        onClick={gaClickEvent}
                        className={`btn-login rounded-[50px] mb-6 text-xl text-white font-bold flex justify-center bg-button-backgroud-color items-center ${
                          loginLoading ? "active" : ""
                        }`}
                      >
                        {loginLoading ? (
                          <div className="signup btn-loader"></div>
                        ) : (
                          <span>Sign In</span>
                        )}
                      </button>
                    )}
                  </div>
                  {process.env.REACT_APP_IS_SIGNUP_BTN_HIDDEN === "true" ? (
                    ""
                  ) : (
                    <a
                      href="#"
                      onClick={googleLogin}
                      className="w-full border border-light-purple rounded-[50px] h-[58px] flex justify-center bg-[#FAFAFA] items-center"
                    >
                      <img
                        className="mr-3"
                        src={googleLogo}
                        alt="google logo"
                      />
                      <span className="text-lg text-thin-light-gray font-normal">
                        Sign In with Google
                      </span>
                    </a>
                  )}
                </div>
                {process.env.REACT_APP_IS_SIGNUP_BTN_HIDDEN === "true" ? (
                  ""
                ) : (
                  <div className="signup-area flex justify-center">
                    <p className="sm:text-lg text-sm text-thin-light-gray font-normal">
                      Don’t have an account?
                      <button
                        type="button"
                        onClick={() => {
                          navigate("/signup");
                        }}
                        className="ml-2 text-purple"
                      >
                        Sign up free
                      </button>
                    </p>
                  </div>
                )}
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <LoginWithDynamicxyz />
              </div>
            </div>
          </div>
        </form>
      </AuthLayout>
    </>
  );
}
