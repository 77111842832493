import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { signup } from "../../../apis/apis";
import { userSignup, signUpGoogle } from "../../../apis/authApis/authApis";
import loginThumb from "../../../assets/images/auth-thumb.svg";
import googleLogo from "../../../assets/images/google-logo.svg";
// import logo from "../../../assets/images/logo-segmint-login-1.png";
import logo from "../../../assets/images/SEGMINT-Logo_Blue.png";
import titleShape from "../../../assets/images/shape/title-shape-two.svg";
import InputCom from "../../Helpers/Inputs/InputCom";
import useAnalyticsEventTracker from "../../../Service/GoogleAnalyticsService";
import MessageBox from "../../MessageBox/messageBox";
import "../../MessageBox/messageBox.css";
export default function SignUp() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [disabledBtn, setDisabledBtn] = React.useState(true);
  const [firstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRepassword] = useState("");
  const [validation, setValidation] = useState(false);
  const [strongPassMsg, setStrongPassMsg] = useState("");
  const [showPass, setShowPass] = React.useState(false);
  const [showConfirmPass, setShowConfirmPass] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let { id } = useParams();
  let { emailparam } = useParams();
  const rememberMe = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (
      !firstName ||
      !LastName ||
      !email ||
      !password ||
      !rePassword ||
      !checked ||
      strongPassMsg ||
      loading
    ) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }
    if (password !== rePassword) {
      setValidation("Password and confirm password do not match");
      setDisabledBtn(true);
    } else {
      setValidation("");
    }
  }, [
    email,
    password,
    rePassword,
    firstName,
    LastName,
    checked,
    loading,
    strongPassMsg,
  ]);
  useEffect(() => {
    if (emailparam) {
      setEmail(emailparam ? emailparam : "");
    }
  }, [emailparam]);
  const gaEventTracker = useAnalyticsEventTracker(
    "Signup",
    "Landed on signup page",
    "Landed",
    "signup"
  );
  const gaClickEvent = useAnalyticsEventTracker(
    "Signup",
    "Clicked on signup button",
    "Clicked",
    "signup"
  );
  useEffect(() => {
    gaEventTracker();
  }, []);
  const onSignUp = async (e) => {
    e.preventDefault();
    if (password !== rePassword) {
      setValidation("Password and confirm password do not match");
      return false;
    }
    setLoading(true);
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const body = {
      firstName: firstName,
      lastName: LastName,
      email: email,
      password: password,
      referralCode: id,
    };
    const isUserValidated = await userSignup(signup, body, config);
    if (isUserValidated.status) {
      sessionStorage.setItem("email", email);
      setTimeout(() => {
        navigate("/verify-email");
        setLoading(false);
      }, [3000]);
      toast.success("User registered successfully!");
    } else {
      toast.error(`${isUserValidated.message}`);
      setLoading(false);
    }
  };
  const googleSignUp = async (e) => {
    e.preventDefault();
    const isUserValidated = await signUpGoogle();
    if (isUserValidated.status) {
      sessionStorage.setItem("email", email);
      setTimeout(() => {
        navigate("/");
      }, [3000]);
      toast.success("User registered successfully!");
    } else {
      toast.error(`${isUserValidated.message}`);
    }
  };
  return (
    <>
      <div className="layout-wrapper">
        <div className="main-wrapper w-full xl:h-screen h-full xl:py-0 py-12">
          <div className="flex w-full">
            <div
              className="xl:flex hidden p-[70px]  flex-col justify-between primary-gradient"
              style={{ flex: "50%", minHeight: "100vh", maxHeight: "100%" }}
            >
              <div className="logo">
                <img
                  src={logo}
                  alt="logo"
                  width="40%"
                  style={{ marginLeft: "-5%", marginTop: "-10%" }}
                />
              </div>
              <div className="thumbnail flex justify-center">
                <img
                  src={loginThumb}
                  alt="login-thumb"
                  style={{
                    objectFit: "contain",
                    width: "75%",
                    height: "100%",
                  }}
                />
              </div>
              <div className="article">
                <p
                  className="text-[50px] font-bold leading-[72px] text-white"
                  style={{ wordWrap: "break-word" }}
                >
                  FracX 
                </p>
              </div>
            </div>
            <form
              onSubmit={onSignUp}
              className="flex-1 justify-center items-center"
              style={{
                flex: "50%",
                minHeight: "100vh",
                maxHeight: "100%",
              }}
            >
              <div className="content-wrapper xl:bg-white  xl:px-7 px-5 2xl:px-[100px] h-full rounded-xl flex flex-col justify-center">
                <div style={{ padding: "24px 0px" }}>
                  <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                    <h1 className="sm:text-5xl text-4xl font-bold  text-dark-gray leading-2">
                      Create Account
                    </h1>
                    <div className="shape sm:w-[377px] w-[280px] -mt-2.5 ml-5">
                      <img
                        src={titleShape}
                        alt="shape"
                        style={{ filter: "hue-rotate(300deg)" }}
                      />
                    </div>
                  </div>
                  <div className="input-area">
                    <div className="input-fl-name mb-5 sm:flex w-full sm:space-x-6 ">
                      <div className="input-item sm:w-1/2 w-full mb-5 sm:mb-0">
                        <InputCom
                          value={firstName}
                          placeholder="Adam"
                          label="First Name"
                          name="first_name"
                          type="text"
                          iconName="people"
                          inputHandler={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="input-item flex-1">
                        <InputCom
                          value={LastName}
                          placeholder="Wathon"
                          label="Last Name"
                          name="Last_name"
                          type="text"
                          iconName="people"
                          inputHandler={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="input-item mb-5">
                      <InputCom
                        value={emailparam ? emailparam : ""}
                        disabled={emailparam ? true : false}
                        placeholder="example@domain.com"
                        label="Email Address"
                        name="email"
                        type="email"
                        iconName="message"
                        inputHandler={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="input-item mb-5">
                      <InputCom
                        value={id ? id : referralCode}
                        disabled={id ? true : false}
                        placeholder="423553"
                        label="Referral Code (Optional)"
                        name="referralCode"
                        type="text"
                        iconName="message"
                        inputHandler={(e) => setReferralCode(e.target.value)}
                      />
                    </div>
                    <div className="input-item mb-5 relative">
                      <InputCom
                        value={password}
                        placeholder="*********"
                        label="Password"
                        name="password"
                        type={!showPass ? "password" : "text"}
                        iconName="password"
                        isPass={true}
                        id="newPassId"
                        letterId="newPassLetterId"
                        capitalId="newPassCapitalId"
                        numberId="newPassNumberId"
                        lengthId="newPassLengthId"
                        specialId="newPassSpecialId"
                        inputHandler={(e) => {
                          var regularExpression =
                            /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
                          if (!regularExpression.test(e.target.value)) {
                            setStrongPassMsg("Password must be strong.");
                          } else {
                            setStrongPassMsg("");
                          }
                          setPassword(e.target.value);
                        }}
                        setShowPass={setShowPass}
                        showPass={showPass}
                      />
                      <MessageBox
                        id="newPassId"
                        letterId="newPassLetterId"
                        capitalId="newPassCapitalId"
                        numberId="newPassNumberId"
                        lengthId="newPassLengthId"
                        specialId="newPassSpecialId"
                      />
                    </div>
                    <div className="input-item mb-5 relative">
                      <InputCom
                        isValidated={validation}
                        value={rePassword}
                        placeholder="*********"
                        label="Re-enter Password"
                        name="rePassword"
                        type={!showConfirmPass ? "password" : "text"}
                        iconName="password"
                        isPass={true}
                        id="confirmPassId"
                        letterId="confirmPassLetterId"
                        capitalId="confirmPassCapitalId"
                        numberId="confirmPassNumberId"
                        lengthId="confirmPassLengthId"
                        specialId="confirmPassSpecialId"
                        inputHandler={(e) => setRepassword(e.target.value)}
                        setShowPass={setShowConfirmPass}
                        showPass={showConfirmPass}
                      />
                      {validation && rePassword ? (
                        <label className="error-message">{validation}</label>
                      ) : null}
                      <MessageBox
                        id="confirmPassId"
                        letterId="confirmPassLetterId"
                        capitalId="confirmPassCapitalId"
                        numberId="confirmPassNumberId"
                        lengthId="confirmPassLengthId"
                        specialId="confirmPassSpecialId"
                      />
                    </div>
                    <div className="forgot-password-area flex justify-between items-center mb-6">
                      <div className="remember-checkbox flex items-center space-x-2.5">
                        <button
                          onClick={rememberMe}
                          type="button"
                          className="w-5 h-5 text-dark-gray flex justify-center items-center border border-light-gray"
                        >
                          {checked && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                        </button>
                        <span className="text-base text-dark-gray">
                          I agree all
                          <a
                            target="_blank"
                            href="https://www.vaneck.com/us/en/privacy-policy/"
                            className="text-base text-purple mx-1 inline-block"
                            rel="noreferrer"
                          >
                            terms and conditions
                          </a>
                          in FracX.
                        </span>
                      </div>
                    </div>
                    <div className="signin-area mb-1" onClick={gaClickEvent}>
                      {disabledBtn ? (
                        <button
                          type="submit"
                          className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-slate-400 font-bold-400 flex justify-center bg-slate-100 items-center disabled:cursor-not-allowed shadow-none"
                          disabled={true}
                        >
                          Sign Up
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-white font-bold-400 flex justify-center bg-purple items-center"
                        >
                          {loading ? "Loading..." : "Sign Up"}
                        </button>
                      )}
                      {!id && !emailparam ? (
                        <a
                          href="#"
                          onClick={googleSignUp}
                          className="w-full border border-light-purple rounded-[50px] h-[58px] flex justify-center bg-[#FAFAFA] items-center"
                        >
                          <img
                            className="mr-3"
                            src={googleLogo}
                            alt="google logo"
                          />
                          <span className="text-lg text-thin-light-gray font-normal">
                            Sign Up with Google
                          </span>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="signup-area flex justify-center">
                      <p className="text-base text-thin-light-gray font-normal">
                        Already have account?
                        <button
                          type="button"
                          className="text-base text-purple"
                          style={{ marginLeft: "2px" }}
                          onClick={() => navigate("/login")}
                        >
                          {" "}
                          Log in
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
