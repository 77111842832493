// import { DAppProvider } from "@usedapp/core";
import { Web3ReactProvider } from "@web3-react/core";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Web3 from "web3";
// import { Web3Provider } from "@ethersproject/providers";
import App from "./App";
import "./index.css";
import store from "./store/store";
import WagmiComponent from "./utils/WagmiComponent";
function getLibrary(provider) {
  return new Web3(provider);
}

const root = document.getElementById("root");
ReactDOM.createRoot(root).render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <WagmiComponent>
          <App />
        </WagmiComponent>
      </Web3ReactProvider>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);
