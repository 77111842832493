import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import Toaster from "./components/Helpers/Toaster";
import Routers from "./Routers";
import { useEagerConnect, useInactiveListener } from "./hooks/useWeb3";
import { useSelector } from "react-redux";
import { WalletChatWidget } from "react-wallet-chat";
import ReactGA from "react-ga4";
import { GeoFence } from "./components/Geo/GeoFence";

// Import hotjar
import { hotjar } from "react-hotjar";

function App() {
  const { connector } = useWeb3React();
  const widgetStateFromRedux = useSelector((state) => state.wallet.widgetState);
  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState();

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // Initialize hotjar with our tracking code
  hotjar.initialize(process.env.REACT_APP_HOTJAR);
  ReactGA.initialize(process.env.REACT_APP_GTAG);

  ReactGA.pageview(window.location.pathname + window.location.search);

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);
  return (
    <>
      <div className="walletchatwidget">
        <WalletChatWidget widgetState={widgetStateFromRedux} />
      </div>
      <GeoFence />
      <Routers />
      <Toaster />
    </>
  );
}

export default App;
