import ReactGA from "react-ga4";

const useAnalyticsEventTracker = (category, action, label, pagepath) => {
  const eventTracker = () => {
    try {
      ReactGA.send({ hitType: "pageview", page: `/${pagepath}` });
      console.log(category, action, label);
      ReactGA.event({
        category: category,
        action: action,
        label: label,
      });
    } catch (err) {
      console.log("REACT GA ERROR:" + err);
    }
  };
  return eventTracker;
};
export default useAnalyticsEventTracker;
