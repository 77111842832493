import React, { useState, useEffect } from "react";
import {  useParams } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
// import { useSelector } from "react-redux";
import { getAllproposal } from "../../Service/graphService";
import PendingProposals from "./PendingProposals";
export default function Proposals() {
  let { multiSigAddress, chainId } = useParams();
  const [reloadAPI, setReloadAPI] = useState(false);

//   const walletInfoFromRedux = useSelector((state) => state.wallet.walletInfo);

  const [pendingProposalData, setPendingProposalData] = useState([]);
  const [invokeGetData, setInvokeGetData] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);

  useEffect(() => {
    console.log("Fecthed", reloadAPI);
    fetchProposalData();
  }, [reloadAPI === true]);

  useEffect(() => {
    fetchProposalData();
    setInvokeGetData(false);
  }, [invokeGetData]);

  useEffect(() => {
    fetchProposalData();
  }, [multiSigAddress]);

  const fetchProposalData = async () => {
    try {
      setDataLoader(true);
      const data = await getAllproposal(multiSigAddress, chainId);
      console.log(data);
      const filterPendingData = data.filter((item) => {
        return item.status === "Pending";
      });

      setPendingProposalData(filterPendingData);
      setDataLoader(false);
      setReloadAPI(false);
    } catch (error) {
      console.log(error);
      setDataLoader(false);
      setReloadAPI(false);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={dataLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="nft-authprofile-wrapper w-full">
        <div className="main-wrapper w-full">
          <PendingProposals
            setReloadAPI={setReloadAPI}
            contractAddress={multiSigAddress}
            pendingProposalData={pendingProposalData}
            chainId={chainId}
          />
        </div>
      </div>
    </>
  );
}
