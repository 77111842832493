import React from "react";
import "./messageBox.css";

function MessageBox({
  id,
  letterId,
  capitalId,
  numberId,
  lengthId,
  specialId,
}) {
  return (
    <div id={id}>
      <h3>Password must contain the following:</h3>
      <p id={letterId} className="invalid">
        A lowercase letter
      </p>
      <p id={capitalId} className="invalid">
        A capital (uppercase) letter
      </p>
      <p id={numberId} className="invalid">
        A number
      </p>
      <p id={specialId} className="invalid">
        A special (!,@,#,$,%,^) characters
      </p>
      <p id={lengthId} className="invalid">
        Minimum 8 characters
      </p>
    </div>
  );
}

export default MessageBox;
