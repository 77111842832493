import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeGateway: 0,
  isFailed: false,
};

export const ipfsGateway = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setActiveGatewayIndex: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.activeGateway = action.payload;
      //   console.log("state", action);
    },
    setIpfsGatewayFailed: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.isFailed = action.payload;
      //   console.log("state", action);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setActiveGatewayIndex, setIpfsGatewayFailed } =
  ipfsGateway.actions;

export default ipfsGateway.reducer;
